import * as React from 'react';

import { retryableLazyImport } from '@/features/app/components/lazy-loader';

export const CreateWorkspacePage = React.lazy(() =>
  retryableLazyImport(() => import('@/pages/create-workspace-page'))
);

export const LogInPage = React.lazy(() =>
  retryableLazyImport(() => import('@/pages/authentication/login-page'))
);

export const EmailVerificationMessagePage = React.lazy(() =>
  retryableLazyImport(() => import('@/pages/email-verification-message-page'))
);

export const EmailVerificationPage = React.lazy(() =>
  retryableLazyImport(
    () => import('@/pages/authentication/email-verification-page')
  )
);

export const AccountSettingsPage = React.lazy(() =>
  retryableLazyImport(() => import('@/pages/account-settings-page'))
);

export const OnboardingFormPage = React.lazy(() =>
  retryableLazyImport(() => import('@/pages/onboarding-form-page'))
);
