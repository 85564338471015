import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { TId } from '@/features/common';
import { useNotifications } from '@/features/notifications';
import { useWorkspaceId } from '@/hooks/router';

import {
  deleteEmailTemplate,
  fetchEmailTemplates,
  fetchEmailTemplateVariables,
  saveEmailTemplate,
  sendEmailTemplatePreview,
  updateEmailTemplate,
} from '../api';
import {
  IEmailTemplate,
  IEmailTemplateVariable,
  TEmailTemplateForSave,
  TEmailTemplateStatus,
  TEmailTemplateType,
} from '../types';

const EMAIL_TEMPLATES_KEY = ['email-templates'];
export const useEmailTemplatesQuery = () => {
  const workspaceId = useWorkspaceId();

  return useQuery({
    queryKey: [...EMAIL_TEMPLATES_KEY, workspaceId],
    queryFn: () => fetchEmailTemplates(workspaceId),
  });
};

export const useSaveEmailTemplateMutation = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceId();
  const { addNotification } = useNotifications();

  return useMutation<
    IEmailTemplate,
    Error,
    {
      workspaceId: TId;
      name: string;
      message: string;
      subject: string;
      type: TEmailTemplateType;
      status: TEmailTemplateStatus;
      candidateTags: TId[];
    }
  >({
    mutationFn: (emailTemplate: TEmailTemplateForSave) =>
      saveEmailTemplate(emailTemplate),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [...EMAIL_TEMPLATES_KEY, workspaceId],
      });
      addNotification({ type: 'saved' });
    },
    ...(options as any),
  });
};

export const useUpdateEmailTemplateMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceId();
  const { addNotification } = useNotifications();

  return useMutation<IEmailTemplate, Error, { emailTemplate: IEmailTemplate }>({
    mutationFn: (params: { emailTemplate: IEmailTemplate }) =>
      updateEmailTemplate(workspaceId, params.emailTemplate),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [...EMAIL_TEMPLATES_KEY, workspaceId],
      });
      addNotification({ type: 'saved' });
    },
    ...(options as any),
  });
};

export const useDeleteEmailTemplateMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceId();
  const { addNotification } = useNotifications();

  return useMutation<TId, Error, { selectedEmailTemplateId: string }>({
    mutationFn: (emailTemplateId: TId) =>
      deleteEmailTemplate(workspaceId, emailTemplateId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [...EMAIL_TEMPLATES_KEY, workspaceId],
      });
      addNotification({ type: 'saved' });
    },
    ...(options as any),
  });
};

export const useEmailTemplateVariablesQuery = () => {
  return useQuery<IEmailTemplateVariable[]>({
    queryKey: ['email-template-variables'],
    queryFn: () => fetchEmailTemplateVariables(),
  });
};

export const useSendEmailTemplatePreviewMutation = (
  options?: UseMutationOptions
) => {
  const workspaceId = useWorkspaceId();

  return useMutation<
    void,
    Error,
    { emailTemplateId: string; emailTemplateDomain: 'workspace' | 'system' }
  >({
    mutationFn: (params: {
      emailTemplateId: TId;
      emailTemplateDomain: 'workspace' | 'system';
    }) =>
      sendEmailTemplatePreview(
        workspaceId,
        params.emailTemplateId,
        params.emailTemplateDomain
      ),
    ...(options as any),
  });
};
