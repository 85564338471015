import React from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import { FormikInputField, TogglLogo } from '@/features/common';
import { useCreateWorkspaceMutation } from '@/features/workspace';
import { useHistory } from '@/hooks/router';

import createWsIllustration from './assets/create-ws-illustration.svg';

const validationSchema = object().shape({
  name: string().required("This can't be blank!"),
});

const initialValues = {
  name: '',
};

export function CreateWorkspace() {
  const history = useHistory();
  const createWorkspaceMutation = useCreateWorkspaceMutation({
    onSuccess: (workspace) => {
      history.push(`/admin/${workspace.id}`);
    },
  });

  const handleSubmit = async (values: { name: string }) => {
    createWorkspaceMutation.mutate(values);
  };

  return (
    <Container>
      <a
        href="https://toggl.com/hire/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <TogglLogo height={46} width={205} />
      </a>

      <img
        src={createWsIllustration}
        alt="Create workspace illustration"
        height={110}
      />
      <Heading>Create a new workspace</Heading>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <FormContent>
          <FormikInputField name="name" label="Workspace name" />
          <DSButton type="submit" disabled={createWorkspaceMutation.isPending}>
            {createWorkspaceMutation.isPending
              ? 'Creating...'
              : 'Create workspace'}
          </DSButton>
        </FormContent>
      </Formik>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 32px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  width: 344px;
  max-width: 100%;
`;

const Heading = styled.h1`
  margin: 0;
  text-align: center;
  font-family: 'GT Haptik';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  color: ${(props) => props.theme.colors.purple[80]};
`;

const FormContent = styled(Form)`
  display: grid;
  gap: 20px;
  width: 100%;

  button {
    width: 100%;
  }
`;
