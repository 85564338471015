import { useMutation, useQueryClient } from '@tanstack/react-query';

import { TId } from '@/features/common';
import { IQuestion } from '@/features/questions';
import { TEST_QUERY_KEY } from '@/features/test';

import {
  createCustomQuestion,
  createLibraryQuestion,
  deleteQuestion,
  regenerateQuestion,
  updateQuestion,
  updateQuestionProperties,
} from '../api';

import {
  FETCH_QUESTIONS_QUERY_KEY,
  LEGACY_FETCH_QUESTIONS_QUERY_KEY,
} from './questions';

interface ICommonQueryParams {
  testId: TId;
  onSuccess?: (data?: unknown) => void;
  onError?: (error: unknown, variables: unknown) => void;
}

export const useDeleteQuestionMutation = ({
  testId,
  onSuccess,
}: ICommonQueryParams) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteQuestion,
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [...LEGACY_FETCH_QUESTIONS_QUERY_KEY, testId],
        }),
        queryClient.invalidateQueries({
          queryKey: [...FETCH_QUESTIONS_QUERY_KEY, testId],
        }),
        queryClient.invalidateQueries({
          queryKey: [...TEST_QUERY_KEY, testId],
        }),
      ]);

      if (onSuccess) {
        onSuccess();
      }
    },
  });
};

export const useUpdateQuestionMutation = ({
  testId,
  onSuccess,
}: ICommonQueryParams) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateQuestion,
    onSuccess: async (data: IQuestion) => {
      await queryClient.invalidateQueries({
        queryKey: [...FETCH_QUESTIONS_QUERY_KEY, testId],
      });
      if (onSuccess) {
        onSuccess(data);
      }
    },
    meta: { skipDefaultErrorHandler: true },
  });
};

export const useRegenerateQuestionMutation = ({
  testId,
  onSuccess,
  onError,
}: ICommonQueryParams) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: regenerateQuestion,
    meta: { skipDefaultErrorHandler: true },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [...FETCH_QUESTIONS_QUERY_KEY, testId],
      });
      if (onSuccess) {
        onSuccess();
      }
    },
    onError,
  });
};

export const useCreateLibraryQuestionMutation = ({
  testId,
  onSuccess,
  onError,
}: ICommonQueryParams) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createLibraryQuestion,
    meta: { skipDefaultErrorHandler: true },
    onSuccess: async (question: IQuestion) => {
      await Promise.all([
        await queryClient.invalidateQueries({
          queryKey: [...FETCH_QUESTIONS_QUERY_KEY, testId],
        }),
        await queryClient.invalidateQueries({
          queryKey: [...TEST_QUERY_KEY, testId],
        }),
      ]);
      if (onSuccess) {
        onSuccess(question);
      }
    },
    onError,
  });
};

export const useCreateCustomQuestionMutation = ({
  testId,
  onSuccess,
}: ICommonQueryParams) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createCustomQuestion,
    onSuccess: async (question: IQuestion) => {
      await Promise.all([
        await queryClient.invalidateQueries({
          queryKey: [...FETCH_QUESTIONS_QUERY_KEY, testId],
        }),
        await queryClient.invalidateQueries({
          queryKey: [...TEST_QUERY_KEY, testId],
        }),
      ]);

      if (onSuccess) {
        onSuccess(question);
      }
    },
  });
};

export const useUpdateQuestionPropertiesMutation = ({
  testId,
  onSuccess,
}: ICommonQueryParams) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateQuestionProperties,
    meta: { skipDefaultErrorHandler: true },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [...FETCH_QUESTIONS_QUERY_KEY, testId],
      });

      if (onSuccess) {
        onSuccess();
      }
    },
  });
};
