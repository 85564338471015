import { useQuery } from '@tanstack/react-query';

import { TId } from '@/features/common';
import { Id } from '@/types/misc';

import { fetchTemplates, fetchTemplate, fetchTemplateTypes } from '../api';

export const TEMPLATES_QUERY_KEY = ['templates'];
export const TEMPLATE_QUERY_KEY = ['template'];
export const TEMPLATE_TYPES_QUERY_KEY = ['template-types'];

export const useTemplatesQuery = (
  params: { query?: string } = {},
  options?: { enabled?: boolean }
) => {
  return useQuery({
    queryKey: [...TEMPLATES_QUERY_KEY, params.query],
    queryFn: () => fetchTemplates(params),
    enabled: options?.enabled ?? true,
  });
};

export const useTemplateQuery = (
  params: { id: TId },
  options?: { enabled?: boolean }
) => {
  return useQuery({
    queryKey: [...TEMPLATE_QUERY_KEY, params.id],
    queryFn: () => fetchTemplate(params),
    enabled: options?.enabled ?? true,
  });
};

export const useTemplateTypesQuery = (
  params: { categoryId: Id },
  options?: { enabled?: boolean }
) => {
  return useQuery({
    queryKey: [...TEMPLATE_TYPES_QUERY_KEY, params.categoryId],
    queryFn: () => fetchTemplateTypes(params),
    enabled: options?.enabled ?? true,
  });
};
