import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  DSButton,
  DSField,
  DSInput,
  DSModal,
  DSSelect,
} from '@hundred5/design-system';
import { useFormikContext } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useMedia } from 'react-use';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { useJobOpeningByIdQuery } from '@/features/job-opening';
import { useCreatePipelineCategoryMutation } from '@/features/pipeline';
import { IAddTestToPipelineFormValues } from '@/features/test-library';

import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  ModalHeaderDescription,
} from './modal';

export const AddNewStageModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [stageName, setStageName] = useState('');
  const isMobile = useMedia('(max-width: 768px)');
  const createPipelineCategoryMutation = useCreatePipelineCategoryMutation();
  const form = useFormikContext<IAddTestToPipelineFormValues>();
  const jobOpeningQuery = useJobOpeningByIdQuery(
    { jobOpeningId: form.values.jobOpeningId! },
    { enabled: !!form.values.jobOpeningId }
  );
  const amplitude = getAmplitudeClient();
  const posthog = usePostHog();
  if (form.values.jobOpeningId === undefined) {
    return null;
  }

  const handleSave = async () => {
    const createdStage = await createPipelineCategoryMutation.mutateAsync({
      jobOpeningId: form.values.jobOpeningId!,
      name: stageName,
      orderWeight: 1,
    });
    form.setFieldValue('pipelineStageId', createdStage.id);
    amplitude?.logEvent('test library/new stage created', {
      jobOpeningId: form.values.jobOpeningId,
    });
    posthog?.capture('hire_admin_app:test library/new stage created', {
      jobOpeningId: form.values.jobOpeningId,
    });
    onClose();
  };

  return (
    <DSModal
      open={open}
      contentStyle={{ width: isMobile ? '100%' : '500px' }}
      layer={2}
    >
      <ModalHeader>Add new stage</ModalHeader>
      <ModalHeaderDescription>
        Add a new stage to your job opening for this skills test
      </ModalHeaderDescription>
      <DSModal.CloseButton onClick={() => onClose()} />

      <DSModal.Separator />
      <Body>
        <DSField for="jobOpening" required label="job opening">
          <DSSelect
            items={[
              {
                id: form.values.jobOpeningId!,
                label: jobOpeningQuery.data?.name ?? '',
              },
            ]}
            selectedItemId={form.values.jobOpeningId}
            onChange={() => {}}
            disabled
          />
        </DSField>
        <DSField for="stage" required label="pipeline stage">
          <DSInput
            placeholder="Name the stage"
            onChange={(event) => setStageName(event.target.value)}
          />
        </DSField>
      </Body>
      <ModalFooter>
        <ModalFooterButtons>
          <DSButton variant="secondary" onClick={onClose}>
            Cancel
          </DSButton>
          <DSButton
            disabled={!!!stageName || createPipelineCategoryMutation.isPending}
            onClick={handleSave}
            variant="primary-purple"
          >
            Save
          </DSButton>
        </ModalFooterButtons>
      </ModalFooter>
    </DSModal>
  );
};

const Body = styled(ModalBody)`
  margin-bottom: 44px;
`;
