import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { TId } from '@/features/common';
import {
  fetchFraudDetectionSettings,
  IFraudDetectionSettings,
  updateFraudDetectionSettings,
} from '@/features/test';

export const FRAUD_DETECTION_SETTINGS_QUERY_KEY = ['fraud-detection-settings'];

export const useFraudDetectionSettingsQuery = (params: { testId: TId }) =>
  useQuery<IFraudDetectionSettings>({
    queryKey: [...FRAUD_DETECTION_SETTINGS_QUERY_KEY, params.testId],
    queryFn: () => fetchFraudDetectionSettings(params),
  });

export const useFraudDetectionSettingsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateFraudDetectionSettings,
    onMutate: async ({ testId, settings }) => {
      await queryClient.cancelQueries({
        queryKey: [...FRAUD_DETECTION_SETTINGS_QUERY_KEY, testId],
      });
      const previousSettings = queryClient.getQueryData([
        ...FRAUD_DETECTION_SETTINGS_QUERY_KEY,
        testId,
      ]);
      queryClient.setQueryData<IFraudDetectionSettings | undefined>(
        [...FRAUD_DETECTION_SETTINGS_QUERY_KEY, testId],
        (oldSettings) => ({
          ...oldSettings,
          ...settings,
        })
      );

      return { previousSettings };
    },
    onError: (_, variables, context) => {
      queryClient.setQueryData(
        [...FRAUD_DETECTION_SETTINGS_QUERY_KEY, variables.testId],
        context?.previousSettings
      );
    },
    onSettled: (_, __, variables) => {
      queryClient.invalidateQueries({
        queryKey: [...FRAUD_DETECTION_SETTINGS_QUERY_KEY, variables.testId],
      });
    },
  });
};
