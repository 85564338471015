import {
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { CANDIDATE_QUERY_KEY } from '@/features/candidate';
import { TId } from '@/features/common';
import {
  createInterview,
  deleteInterview,
  fetchInterview,
  IInterview,
  updateInterview,
} from '@/features/interview';
import {
  PIPELINE_CANDIDATES_KEY,
  PIPELINE_CATEGORIES_KEY,
} from '@/features/pipeline';

export const INTERVIEW_QUERY_KEY = ['interview'];

export const useInterviewQuery = (
  params: {
    interviewId: TId;
  },
  options?: { enabled: boolean }
) => {
  return useQuery({
    queryKey: [...INTERVIEW_QUERY_KEY, params.interviewId],
    queryFn: () => fetchInterview({ interviewId: params.interviewId }),
    enabled: options?.enabled ?? true,
  });
};

export const useCreateInterviewMutation = (
  params: {
    jobOpeningId: TId;
  },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation<
    IInterview,
    Error,
    { jobOpeningId: TId; categoryId: TId; name: string }
  >({
    mutationFn: createInterview,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [...PIPELINE_CATEGORIES_KEY, params.jobOpeningId],
      });
    },
    ...(options as any),
  });
};

export const useUpdateInterviewMutation = (
  params: {
    interviewId: TId;
  },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation<IInterview, Error, { interview: IInterview }>({
    mutationFn: updateInterview,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [...INTERVIEW_QUERY_KEY, params.interviewId],
      });
    },
    ...(options as any),
  });
};

export const useDeleteInterviewMutation = (
  params: {
    jobOpeningId: TId;
  },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, { interviewId: TId }>({
    mutationFn: deleteInterview,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [...PIPELINE_CATEGORIES_KEY, params.jobOpeningId],
      });
      queryClient.invalidateQueries({
        queryKey: [...PIPELINE_CANDIDATES_KEY, params.jobOpeningId],
      });
      queryClient.invalidateQueries({ queryKey: CANDIDATE_QUERY_KEY });
    },
    ...(options as any),
  });
};
