import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import {
  CANDIDATE_EMAILS_QUERY_KEY,
  CANDIDATE_HISTORY_QUERY_KEY,
  deleteCandidate,
  exportCandidate,
  fetchCandidate,
  hireCandidate,
  ICandidate,
  ICandidateAttributes,
  markCandidateAsSeen,
  rejectCandidate,
  restoreCandidate,
  undoHireCandidate,
  unlockCandidate,
  updateCandidate,
} from '@/features/candidate';
import { TId } from '@/features/common';
import { IJobOpening, JOB_OPENING_QUERY_KEY } from '@/features/job-opening';
import { useNotifications } from '@/features/notifications';
import { PIPELINE_CANDIDATES_KEY } from '@/features/pipeline/queries/pipeline-candidates';
import { WORKSPACE_STATS_QUERY_KEY } from '@/features/workspace';
import { useJobOpeningIdOrNull, useWorkspaceId } from '@/hooks/router';

export const CANDIDATE_QUERY_KEY = ['candidate'];
export const CANDIDATE_ANSWER_VIDEO_URL_QUERY_KEY = ['candidateAnswerVideoUrl'];
//region Queries
export const useCandidateQuery = (
  params: { candidateId: TId },
  options?: { enabled?: boolean }
) => {
  return useQuery<ICandidate>({
    queryKey: [...CANDIDATE_QUERY_KEY, params.candidateId],
    queryFn: () => fetchCandidate(params),
    staleTime: 5 * 60 * 1000, //  5 minutes of stale time
    enabled: options?.enabled ?? true,
  });
};

export const useCandidateAnswerVideoUrlQuery = (params: {
  candidateId: TId;
  testId: TId;
  questionId: TId;
}) => {
  return useQuery<ICandidate, unknown, string | null>({
    queryKey: [
      ...CANDIDATE_ANSWER_VIDEO_URL_QUERY_KEY,
      params.candidateId,
      params.testId,
      params.questionId,
    ],
    queryFn: () => fetchCandidate(params),
    staleTime: 60 * 60 * 1000, //  1h of stale time
    select: (data: ICandidate): string | null => {
      const candidateTest =
        data?.tests.find((test) => test.testId === params.testId) || undefined;
      const answer = candidateTest?.answers?.find(
        (answer) => answer.questionId === params.questionId
      );
      return answer?.recordingUrl || null;
    },
  });
};
//endregion

//region Mutations to be deleted unlocked_candidates, user can unlock a candidate only by upgrading their plan
export const useUnlockCandidateMutation = (options?: UseMutationOptions) => {
  const jobOpeningId = useJobOpeningIdOrNull();
  const queryClient = useQueryClient();
  const { addNotification } = useNotifications();

  return useMutation({
    mutationFn: unlockCandidate,
    onSuccess: ({ unlockableTestTakersRemaining }, { candidateId }: any) => {
      void queryClient.invalidateQueries({
        queryKey: [...CANDIDATE_QUERY_KEY, candidateId],
      });

      queryClient.setQueryData(
        [...JOB_OPENING_QUERY_KEY, jobOpeningId],
        (oldJobOpening: IJobOpening) => ({
          ...oldJobOpening,
          unlockableTestTakersRemaining,
        })
      );

      queryClient.invalidateQueries({ queryKey: PIPELINE_CANDIDATES_KEY });

      addNotification({ type: 'candidate_unlocked' });
    },
    ...(options as any),
  });
};

export const useUpdateCandidateMutation = (
  options?: UseMutationOptions<
    ICandidate,
    unknown,
    {
      candidateId: TId;
      attributes: Partial<ICandidateAttributes>;
    }
  >
) => {
  const onUpdateCandidate = useOnUpdateCandidate();

  return useMutation({
    mutationFn: updateCandidate,
    ...(options as any),
    onSuccess: (data: ICandidate, variables: any, context) => {
      options?.onSuccess?.(data, variables, context);
      onUpdateCandidate(data, variables);
    },
  });
};

export const useExportCandidateMutation = (options?: UseMutationOptions) =>
  useMutation<string, Error, { candidateId: TId }>({
    mutationFn: exportCandidate,
    ...(options as any),
  });

export const useDeleteCandidateMutation = (
  options?: UseMutationOptions<boolean, unknown, { candidateId: TId }>
) => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceId();
  return useMutation({
    mutationFn: deleteCandidate,
    ...(options as any),
    onSuccess: (data: boolean, variables: any, context) => {
      options?.onSuccess?.(data, variables, context);

      queryClient.invalidateQueries({ queryKey: PIPELINE_CANDIDATES_KEY });
      queryClient.invalidateQueries({
        queryKey: [...WORKSPACE_STATS_QUERY_KEY, workspaceId],
      });
    },
  });
};

export const useRejectCandidateMutation = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: rejectCandidate,
    onSuccess: (_, variables: any) => {
      queryClient.invalidateQueries({
        queryKey: [...CANDIDATE_QUERY_KEY, variables.candidateId],
      });
      queryClient.invalidateQueries({ queryKey: PIPELINE_CANDIDATES_KEY });
      queryClient.invalidateQueries({
        queryKey: [...CANDIDATE_HISTORY_QUERY_KEY, variables.candidateId],
      });

      queryClient.invalidateQueries({
        queryKey: [...CANDIDATE_EMAILS_QUERY_KEY, variables.candidateId],
      });

      queryClient.invalidateQueries({
        queryKey: [...WORKSPACE_STATS_QUERY_KEY, variables.candidateId],
      });
    },
    ...(options as any),
  });
};

export const useRestoreCandidateMutation = (options?: UseMutationOptions) =>
  useMutation<ICandidate, Error, { candidateId: TId }>({
    mutationFn: restoreCandidate,
    onSuccess: useOnUpdateCandidate(),
    ...(options as any),
  });

export const useMarkCandidateAsSeenMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: markCandidateAsSeen,
    onSuccess: (_, variables: any) => {
      queryClient.setQueryData(
        [...CANDIDATE_QUERY_KEY, variables.candidateId],
        (cachedCandidate: ICandidate) => ({
          ...cachedCandidate,
          seen: true,
        })
      );

      queryClient.invalidateQueries({ queryKey: PIPELINE_CANDIDATES_KEY });
    },
    ...(options as any),
  });
};

export const useHireCandidateMutation = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      candidateId,
    }: {
      candidateId: TId;
      jobOpeningId: TId;
      categoryId: TId;
    }) => hireCandidate({ candidateId }),
    onSuccess: (_, variables: any) => {
      queryClient.invalidateQueries({
        queryKey: [...CANDIDATE_QUERY_KEY, variables.candidateId],
      });
      queryClient.invalidateQueries({
        queryKey: [
          ...PIPELINE_CANDIDATES_KEY,
          variables.jobOpeningId,
          variables.categoryId,
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [...CANDIDATE_HISTORY_QUERY_KEY, variables.candidateId],
      });
    },
    ...(options as any),
  });
};

export const useUndoHireCandidateMutation = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      candidateId,
    }: {
      candidateId: TId;
      jobOpeningId: TId;
      categoryId: TId;
    }) => undoHireCandidate({ candidateId }),
    onSuccess: (_, variables: any) => {
      queryClient.invalidateQueries({
        queryKey: [...CANDIDATE_QUERY_KEY, variables.candidateId],
      });
      queryClient.invalidateQueries({
        queryKey: [
          ...PIPELINE_CANDIDATES_KEY,
          variables.jobOpeningId,
          variables.categoryId,
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [...CANDIDATE_HISTORY_QUERY_KEY, variables.candidateId],
      });
    },
    ...(options as any),
  });
};

//endregion

//region Util hooks
const useOnUpdateCandidate = () => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceId();

  return (data: ICandidate, { candidateId }: { candidateId: TId }) => {
    queryClient.setQueryData(
      [...CANDIDATE_QUERY_KEY, candidateId],
      (cachedCandidate: ICandidate) => ({ ...cachedCandidate, ...data })
    );

    queryClient.invalidateQueries({ queryKey: PIPELINE_CANDIDATES_KEY });

    queryClient.invalidateQueries({
      queryKey: [...CANDIDATE_HISTORY_QUERY_KEY, candidateId],
    });
    queryClient.invalidateQueries({
      queryKey: [...CANDIDATE_EMAILS_QUERY_KEY, candidateId],
    });

    queryClient.invalidateQueries({
      queryKey: [...WORKSPACE_STATS_QUERY_KEY, workspaceId],
    });
  };
};
//endregion
