import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import {
  BillingAccount,
  BillingInfo,
  BillingInfoAttributes,
  BillingPlan,
  BillingPreview,
  CardInfo,
  changeSubscriptionPlan,
  changeSubscriptionPlanPreview,
  clearBillingInfo,
  deleteSubscription,
  fetchBillingEmail,
  fetchBillingInfo,
  fetchCardInfo,
  fetchInvoices,
  fetchSubscription,
  Invoice,
  PaymentMethodToken,
  updateBillingEmail,
  updateBillingInfo,
  updateCardInfo,
} from '@/features/billing';
import { DASHBOARD_JOB_OPENINGS_QUERY_KEY } from '@/features/dashboard';
import { ME_QUERY_KEY, PREFERENCES_KEY } from '@/features/me';
import { useNotifications } from '@/features/notifications';
import { WORKSPACE_STATS_QUERY_KEY } from '@/features/workspace';
import { useWorkspaceId } from '@/hooks/router';

const BILLING_KEY = ['billing'];

export const useBillingInfoQuery = () => {
  const workspaceId = useWorkspaceId();

  return useQuery<BillingInfo | null>({
    queryKey: [...BILLING_KEY, workspaceId, 'info'],
    queryFn: () => fetchBillingInfo(workspaceId),
  });
};

export const useCardInfoQuery = () => {
  const workspaceId = useWorkspaceId();

  return useQuery<CardInfo | null>({
    queryKey: [...BILLING_KEY, workspaceId, 'card'],
    queryFn: () => fetchCardInfo(workspaceId),
  });
};

export const useInvoicesQuery = (params: { cursor: string | null }) => {
  const workspaceId = useWorkspaceId();

  return useQuery<{
    invoices: { [id: string]: Invoice };
    cursor: string | null;
  }>({
    queryKey: [...BILLING_KEY, workspaceId, 'invoices', params.cursor],
    queryFn: () => fetchInvoices(workspaceId, params.cursor),
  });
};

export const useSubscriptionQuery = () => {
  const workspaceId = useWorkspaceId();

  return useQuery<BillingAccount | null>({
    queryKey: [...BILLING_KEY, workspaceId, 'subscription'],
    queryFn: () => fetchSubscription(workspaceId),
  });
};

export const useBillingEmailQuery = () => {
  const workspaceId = useWorkspaceId();

  return useQuery<string | null>({
    queryKey: [...BILLING_KEY, workspaceId, 'email'],
    queryFn: () => fetchBillingEmail(workspaceId),
  });
};

export const useRemoveBillingInfoMutation = (options?: UseMutationOptions) => {
  const workspaceId = useWorkspaceId();
  const queryClient = useQueryClient();

  const { onSuccess, ...restOptions } = options ?? {};
  return useMutation({
    mutationFn: () => clearBillingInfo(workspaceId),
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: [...BILLING_KEY, workspaceId],
      });
      onSuccess?.(data, variables as any, context);
    },
    ...(restOptions as any),
  });
};

export const useDeleteSubscriptionMutation = (options?: UseMutationOptions) => {
  const workspaceId = useWorkspaceId();
  const queryClient = useQueryClient();

  const { onSuccess, ...restOptions } = options ?? {};

  return useMutation({
    mutationFn: () => deleteSubscription(workspaceId),
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: [...BILLING_KEY, workspaceId],
      });
      onSuccess?.(data, variables as any, context);
    },
    ...(restOptions as any),
  });
};

export const useChangeSubscriptionPlanMutation = (
  options?: UseMutationOptions
) => {
  const workspaceId = useWorkspaceId();
  const queryClient = useQueryClient();
  const { addNotification } = useNotifications();

  const { onSuccess, ...restOptions } = options ?? {};

  return useMutation<
    BillingAccount,
    Error,
    { plan: BillingPlan; challengeToken?: string; couponCode?: string }
  >({
    mutationFn: ({
      plan,
      challengeToken,
      couponCode,
    }: {
      plan: BillingPlan;
      challengeToken?: string;
      couponCode?: string;
    }) => changeSubscriptionPlan(workspaceId, plan, challengeToken, couponCode),

    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: [...BILLING_KEY, workspaceId],
      });
      await queryClient.invalidateQueries({ queryKey: ME_QUERY_KEY });
      queryClient.invalidateQueries({ queryKey: PREFERENCES_KEY });

      queryClient.invalidateQueries({
        queryKey: [...WORKSPACE_STATS_QUERY_KEY, workspaceId],
      });
      queryClient.invalidateQueries({
        queryKey: [...DASHBOARD_JOB_OPENINGS_QUERY_KEY],
      });
      addNotification({ type: 'saved' });
      onSuccess?.(data, variables as any, context);
    },
    ...(restOptions as any),
  });
};

export const useUpdateBillingInfoMutation = (options?: UseMutationOptions) => {
  const workspaceId = useWorkspaceId();
  const queryClient = useQueryClient();

  const { onSuccess, ...restOptions } = options ?? {};

  return useMutation<
    BillingInfo,
    Error,
    { billingInfo: BillingInfo; attributes: any }
  >({
    mutationFn: ({
      billingInfo,
      attributes,
    }: {
      billingInfo: BillingInfo;
      attributes: BillingInfoAttributes;
    }) => updateBillingInfo(billingInfo, attributes),
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: [...BILLING_KEY, workspaceId],
      });
      onSuccess?.(data, variables as any, context);
    },
    ...(restOptions as any),
  });
};

export const useUpdateBillingEmailMutation = (options?: UseMutationOptions) => {
  const workspaceId = useWorkspaceId();
  const queryClient = useQueryClient();

  const { onSuccess, ...restOptions } = options ?? {};

  return useMutation<void, Error, { email: string }>({
    mutationFn: ({ email }: { email: string }) =>
      updateBillingEmail(workspaceId, email),
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: [...BILLING_KEY, workspaceId],
      });
      onSuccess?.(data, variables as any, context);
    },
    ...(restOptions as any),
  });
};

export const useUpdateCardInfoMutation = (options?: UseMutationOptions) => {
  const workspaceId = useWorkspaceId();
  const queryClient = useQueryClient();

  const { onSuccess, ...restOptions } = options ?? {};

  return useMutation<
    CardInfo,
    Error,
    { initialToken: string; challengeToken?: string }
  >({
    mutationFn: ({
      initialToken,
      challengeToken,
    }: {
      initialToken: PaymentMethodToken;
      challengeToken?: PaymentMethodToken;
    }) => updateCardInfo(workspaceId, initialToken, challengeToken),
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: [...BILLING_KEY, workspaceId],
      });
      onSuccess?.(data, variables as any, context);
    },
    ...(restOptions as any),
  });
};

export const useChangeSubscriptionPlanPreviewMutation = (
  options?: UseMutationOptions
) => {
  const workspaceId = useWorkspaceId();
  const queryClient = useQueryClient();

  const { onSuccess, ...restOptions } = options ?? {};

  return useMutation<
    BillingPreview,
    Error,
    { plan: BillingPlan; couponCode?: string }
  >({
    mutationFn: ({
      plan,
      couponCode,
    }: {
      plan: BillingPlan;
      couponCode?: string;
    }) => {
      return changeSubscriptionPlanPreview(workspaceId, plan, couponCode);
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: [...BILLING_KEY, workspaceId],
      });
      onSuccess?.(data, variables as any, context);
    },
    ...(restOptions as any),
  });
};
