import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { TId } from '@/features/common';
import { ME_QUERY_KEY } from '@/features/me';
import {
  deleteWorkspaceUser,
  fetchWorkspaceUsers,
  updateWorkspaceUser,
  WorkspaceUser,
} from '@/features/workspace';
import { useWorkspaceIdOrNull } from '@/hooks/router';
import { Role } from '@/types/role';
import { isSupportEnv } from '@/utils/env';

export const WORKSPACE_USERS_QUERY_KEY = ['workspace', 'users'];

export const useWorkspaceUsersQuery = (options?: { enabled?: boolean }) => {
  const workspaceId = useWorkspaceIdOrNull();
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(WORKSPACE_USERS_QUERY_KEY, {
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [...WORKSPACE_USERS_QUERY_KEY, workspaceId],
      }),
  });

  return useQuery<WorkspaceUser[]>({
    queryKey: [...WORKSPACE_USERS_QUERY_KEY, workspaceId],
    queryFn: () => fetchWorkspaceUsers({ workspaceId: workspaceId! }),
    enabled:
      Boolean(workspaceId) && !isSupportEnv() && (options?.enabled ?? true),
    placeholderData: () => [],
  });
};

export const useUpdateWorkspaceUserMutation = (
  options?: UseMutationOptions
) => {
  return useMutation<
    void,
    Error,
    { workspaceId: TId; userId: TId; role: Role }
  >({
    mutationFn: updateWorkspaceUser,
    mutationKey: WORKSPACE_USERS_QUERY_KEY,
    ...(options as any),
  });
};

export const useDeleteWorkspaceUserMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceIdOrNull();

  return useMutation<void, Error, { workspaceId: TId; userId: TId }>({
    mutationFn: deleteWorkspaceUser,
    mutationKey: WORKSPACE_USERS_QUERY_KEY,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [...ME_QUERY_KEY] });
      await queryClient.invalidateQueries({
        queryKey: [...WORKSPACE_USERS_QUERY_KEY, workspaceId],
      });
    },
    ...(options as any),
  });
};
