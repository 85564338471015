import { useMutation, useQuery } from '@tanstack/react-query';

import { ME_QUERY_KEY } from '@/features/me';
import { queryClient } from '@/utils/reactQuery';

import {
  closeAccount,
  fetchTogglAccountsMe,
  updateTogglAccountsMe,
} from '../api';

const TOGGL_ACCOUNTS_ME_KEY = ['toggl-accounts', 'me'];

export const useTogglAccountsMeQuery = (options?: { enabled?: boolean }) => {
  return useQuery({
    queryKey: [...TOGGL_ACCOUNTS_ME_KEY],
    queryFn: fetchTogglAccountsMe,
    enabled: options?.enabled ?? true,
  });
};

export const useCloseAccountMutation = () => {
  return useMutation({
    mutationFn: closeAccount,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [...TOGGL_ACCOUNTS_ME_KEY] });
    },
  });
};

export const useUpdateTogglAccountsMeMutation = () => {
  return useMutation({
    mutationFn: updateTogglAccountsMe,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [...TOGGL_ACCOUNTS_ME_KEY] });
      queryClient.invalidateQueries({ queryKey: ME_QUERY_KEY });
    },
  });
};
