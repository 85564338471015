import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { TId } from '@/features/common';
import { useNotifications } from '@/features/notifications';
import { PIPELINE_CATEGORIES_KEY } from '@/features/pipeline';

import {
  fetchRecommendedCandidates,
  sendInvitationRecommendedCandidate,
} from '../api/recommended-candidates';
import { IRecommendedCandidateEmail, IRecommendedCandidates } from '../types';

export const RECOMMENDED_CANDIDATES_KEY = ['recommended-candidates'];

export const useRecommendedCandidatesQuery = (params: {
  jobOpeningId: TId;
}) => {
  return useQuery<IRecommendedCandidates>({
    queryKey: [...RECOMMENDED_CANDIDATES_KEY, params.jobOpeningId],
    queryFn: () =>
      fetchRecommendedCandidates({ jobOpeningId: params.jobOpeningId }),
  });
};

export const useRecommendedCandidatesInvitationMutation = (
  params: { jobOpeningId: TId; categoryId: TId },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const { addNotification } = useNotifications();

  return useMutation<
    { candidateId: TId },
    Error,
    {
      jobOpeningId: TId;
      emailData: {
        candidateId: String;
        pipelineStageId: String;
        email: IRecommendedCandidateEmail;
      };
    }
  >({
    mutationFn: sendInvitationRecommendedCandidate,
    mutationKey: RECOMMENDED_CANDIDATES_KEY,
    onSuccess() {
      addNotification({ type: 'email_sent' });
      queryClient.invalidateQueries({ queryKey: RECOMMENDED_CANDIDATES_KEY });
      queryClient.invalidateQueries({
        queryKey: [
          ...PIPELINE_CATEGORIES_KEY,
          params.jobOpeningId,
          params.categoryId,
        ],
      });
    },
    ...(options as any),
  });
};
