import { UseMutationOptions, useMutation } from '@tanstack/react-query';

import { logout } from '../api';

const TOGGL_ACCOUNTS_SESSIONS_KEY = ['toggl-accounts', 'sessions'];

export const useLogoutMutation = (options?: UseMutationOptions) => {
  return useMutation<
    void,
    Error,
    {
      closeAllSessions?: boolean;
    }
  >({
    mutationFn: logout,
    mutationKey: TOGGL_ACCOUNTS_SESSIONS_KEY,
    ...(options as any),
  });
};
