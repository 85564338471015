import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { TId } from '@/features/common';

import { fetchQuestions, sortQuestions } from '../api';

export const LEGACY_FETCH_QUESTIONS_QUERY_KEY = ['legacy-questions'];
export const FETCH_QUESTIONS_QUERY_KEY = ['questions'];

export const useQuestionsQuery = (
  params: {
    testId: TId;
  },
  options?: { refetchOnMount: boolean | 'always' }
) =>
  useQuery({
    queryKey: [...FETCH_QUESTIONS_QUERY_KEY, params.testId],
    queryFn: () => fetchQuestions(params),
    enabled: !!params.testId,
    refetchOnMount: options?.refetchOnMount ?? 'always',
  });

export const useSortQuestionsMutation = (params: { testId: TId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (questionIds: TId[]) =>
      sortQuestions({ testId: params.testId, questionIds }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [...FETCH_QUESTIONS_QUERY_KEY, params.testId],
      }),
  });
};
