import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { useCandidateSearchParams } from '@/features/candidate-search';
import { TId } from '@/features/common';
import { DASHBOARD_JOB_OPENINGS_QUERY_KEY } from '@/features/dashboard';
import {
  addCandidate,
  cloneJobOpening,
  closeJobOpening,
  createJobOpening,
  deleteJobOpening,
  disableJobOpeningReapply,
  enableJobOpeningReapply,
  fetchJobOpening,
  IJobOpening,
  openJobOpening,
  patchJobOpening,
  pauseJobOpening,
  TJobOpeningReapplyAfterUnit,
  unpauseJobOpening,
} from '@/features/job-opening';
import { PIPELINE_CANDIDATES_KEY } from '@/features/pipeline';
import {
  WORKSPACE_CANDIDATES_KEY,
  WORKSPACE_CANDIDATES_STATS_QUERY_KEY,
  WORKSPACE_STATS_QUERY_KEY,
} from '@/features/workspace';
import { useJobOpeningId, useWorkspaceId } from '@/hooks/router';
import { Id } from '@/types/misc';

export const JOB_OPENING_QUERY_KEY = ['job-opening'];

export const useJobOpeningQuery = (options?: {
  enabled?: boolean;
  retry?: boolean;
}) => {
  const jobOpeningId = useJobOpeningId();

  return useQuery<IJobOpening>({
    queryKey: [...JOB_OPENING_QUERY_KEY, jobOpeningId],
    queryFn: () => fetchJobOpening({ jobOpeningId }),
    enabled: options?.enabled ?? true,
    retry: options?.retry ?? false,
  });
};

export const useJobOpeningByIdQuery = (
  params: { jobOpeningId?: Id },
  options?: { enabled?: boolean; meta?: Record<string, unknown> }
) => {
  return useQuery<IJobOpening>({
    queryKey: [...JOB_OPENING_QUERY_KEY, params.jobOpeningId],
    queryFn: () => fetchJobOpening({ jobOpeningId: params.jobOpeningId! }),
    enabled: !!params?.jobOpeningId && (options?.enabled ?? true),
    meta: {
      ...options?.meta,
      skipDefaultErrorHandler: options?.meta?.skipDefaultErrorHandler ?? false,
    },
  });
};

export const useUpdateJobOpeningMutation = (
  params: {
    jobOpeningId: TId;
    onSuccess?: () => void;
  },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...rest } = options || {};

  return useMutation<IJobOpening, Error, { jobOpeningId: TId; settings: {} }>({
    mutationFn: patchJobOpening,
    mutationKey: JOB_OPENING_QUERY_KEY,
    onSuccess(data) {
      if (params.onSuccess) {
        params.onSuccess();
      }
      const queryKey = [...JOB_OPENING_QUERY_KEY, params.jobOpeningId];
      queryClient.setQueryData(queryKey, data);
    },
    ...(rest as any),
  });
};

export const usePauseJobOpeningMutation = (
  params: {
    jobOpeningId: TId;
  },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, { jobOpeningId: TId; pausedAt: Date }>({
    mutationFn: pauseJobOpening,
    mutationKey: JOB_OPENING_QUERY_KEY,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [...JOB_OPENING_QUERY_KEY, params.jobOpeningId],
      });
      queryClient.invalidateQueries({
        queryKey: DASHBOARD_JOB_OPENINGS_QUERY_KEY,
      });
    },
    ...(options as any),
  });
};

export const useUnpauseJobOpeningMutation = (
  params: {
    jobOpeningId: TId;
  },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, { jobOpeningId: TId }>({
    mutationFn: unpauseJobOpening,
    mutationKey: JOB_OPENING_QUERY_KEY,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [...JOB_OPENING_QUERY_KEY, params.jobOpeningId],
      });
      queryClient.invalidateQueries({
        queryKey: DASHBOARD_JOB_OPENINGS_QUERY_KEY,
      });
    },
    ...(options as any),
  });
};

/**
 * Enable job opening reapply
 * @param params - includes job opening id and an optional callback for mutation success
 * @param options - mutation options
 */
export const useEnableJobOpeningReapplyMutation = (
  params: {
    jobOpeningId: TId;
  },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation<
    void,
    Error,
    {
      jobOpeningId: TId;
      reapplyAfter: number;
      reapplyAfterUnit: TJobOpeningReapplyAfterUnit;
    }
  >({
    mutationFn: enableJobOpeningReapply,
    mutationKey: JOB_OPENING_QUERY_KEY,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [...JOB_OPENING_QUERY_KEY, params.jobOpeningId],
      });
    },
    ...(options as any),
  });
};

/**
 * Disable job opening reapply
 * @param params - includes job opening id and an optional callback for mutation success
 * @param options - mutation options
 */
export const useDisableJobOpeningReapplyMutation = (
  params: {
    jobOpeningId: TId;
  },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, { jobOpeningId: TId }>({
    mutationFn: disableJobOpeningReapply,
    mutationKey: JOB_OPENING_QUERY_KEY,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [...JOB_OPENING_QUERY_KEY, params.jobOpeningId],
      });
    },
    ...(options as any),
  });
};

// TODO: move to @/features/share
export const useAddCandidateMutation = () => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceId();
  const { searchParams: candidatesSearchParams } = useCandidateSearchParams();

  return useMutation({
    mutationFn: addCandidate,
    onSuccess: (_, params) => {
      void queryClient.invalidateQueries({
        queryKey: [
          ...WORKSPACE_CANDIDATES_KEY,
          candidatesSearchParams,
          workspaceId,
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [...WORKSPACE_STATS_QUERY_KEY, workspaceId],
      });
      queryClient.invalidateQueries({
        queryKey: [...WORKSPACE_CANDIDATES_STATS_QUERY_KEY, workspaceId],
      });
      queryClient.invalidateQueries({
        queryKey: [
          ...PIPELINE_CANDIDATES_KEY,
          params.jobOpeningId,
          params.attributes.categoryId,
        ],
      });
    },
  });
};

export const useCreateJobOpeningMutation = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceId();

  return useMutation<IJobOpening, Error, {}>({
    mutationFn: (params: { name?: string }) =>
      createJobOpening({
        workspaceId,
        name: params.name,
      }),
    mutationKey: JOB_OPENING_QUERY_KEY,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [...DASHBOARD_JOB_OPENINGS_QUERY_KEY],
      });

      queryClient.invalidateQueries({
        queryKey: [...WORKSPACE_STATS_QUERY_KEY, workspaceId],
      });
    },
    ...(options as any),
  });
};

export const useDeleteJobOpeningMutation = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceId();

  return useMutation<void, Error, { jobOpeningId: TId }>({
    mutationFn: deleteJobOpening,
    mutationKey: JOB_OPENING_QUERY_KEY,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [...DASHBOARD_JOB_OPENINGS_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [...WORKSPACE_CANDIDATES_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [...WORKSPACE_STATS_QUERY_KEY, workspaceId],
      });
      queryClient.invalidateQueries({
        queryKey: [...WORKSPACE_CANDIDATES_STATS_QUERY_KEY, workspaceId],
      });
    },
    ...(options as any),
  });
};

export const useCloneJobOpeningMutation = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceId();

  return useMutation<IJobOpening, Error, { jobOpeningId: TId; name: string }>({
    mutationFn: cloneJobOpening,
    mutationKey: JOB_OPENING_QUERY_KEY,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [...DASHBOARD_JOB_OPENINGS_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [...WORKSPACE_STATS_QUERY_KEY, workspaceId],
      });
      queryClient.invalidateQueries({
        queryKey: [...WORKSPACE_CANDIDATES_STATS_QUERY_KEY, workspaceId],
      });
    },
    ...(options as any),
  });
};

export const useCloseJobOpeningMutation = (
  params: { jobOpeningId: TId },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceId();

  return useMutation<void, Error, { jobOpeningId: TId }>({
    mutationFn: closeJobOpening,
    mutationKey: JOB_OPENING_QUERY_KEY,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [...JOB_OPENING_QUERY_KEY, params.jobOpeningId],
      });
      queryClient.invalidateQueries({
        queryKey: [...DASHBOARD_JOB_OPENINGS_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [...WORKSPACE_STATS_QUERY_KEY, workspaceId],
      });
    },
    ...(options as any),
  });
};

export const useOpenJobOpeningMutation = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceId();

  return useMutation<void, Error, { jobOpeningId: TId }>({
    mutationFn: openJobOpening,
    mutationKey: JOB_OPENING_QUERY_KEY,
    onSuccess(_, variables: any) {
      queryClient.invalidateQueries({
        queryKey: [...JOB_OPENING_QUERY_KEY, variables.jobOpeningId],
      });
      queryClient.invalidateQueries({
        queryKey: [...DASHBOARD_JOB_OPENINGS_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [...WORKSPACE_STATS_QUERY_KEY, workspaceId],
      });
    },
    ...(options as any),
  });
};
