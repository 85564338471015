import {
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { TId } from '@/features/common';
import {
  IRecommendationSettings,
  RECOMMENDED_CANDIDATES_KEY,
  fetchRecommendationSettings,
  updateRecommendationSettings,
} from '@/features/job-opening';
import { useNotifications } from '@/features/notifications';

export const RECOMMENDATION_SETTINGS_KEY = ['recommendation-settings'];

export const useRecommendationSettingsQuery = (params: {
  jobOpeningId: TId;
}) => {
  return useQuery<IRecommendationSettings>({
    queryKey: [...RECOMMENDATION_SETTINGS_KEY, params.jobOpeningId],
    queryFn: () =>
      fetchRecommendationSettings({ jobOpeningId: params.jobOpeningId }),
  });
};

export const useRecommendationSettingsMutation = (
  params: { jobOpeningId: TId },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const { addNotification } = useNotifications();

  return useMutation<
    IRecommendationSettings,
    Error,
    { jobOpeningId: TId; settings: IRecommendationSettings }
  >({
    mutationFn: updateRecommendationSettings,
    mutationKey: RECOMMENDATION_SETTINGS_KEY,
    onSuccess() {
      addNotification({ type: 'saved' });
      queryClient.invalidateQueries({ queryKey: RECOMMENDATION_SETTINGS_KEY });
      queryClient.invalidateQueries({
        queryKey: [...RECOMMENDED_CANDIDATES_KEY, params.jobOpeningId],
      });
    },
    ...(options as any),
  });
};
