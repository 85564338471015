import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { DSButton, DSContentBlock } from '@hundred5/design-system';
import * as Sentry from '@sentry/react';
import { usePostHog } from 'posthog-js/react';
import { useLocation } from 'react-router';

import { getAmplitudeClient } from '@/features/amplitude';
import { ConfirmModal } from '@/features/common';
import { GmailIcon } from '@/features/integrations';
import {
  useDeleteGmailDataMutation,
  useDeleteGmailIntegrationMutation,
  useEnableGmailCallbackMutation,
  useEnableGmailMutation,
  useMeQuery,
} from '@/features/me';
import { useNotifications } from '@/features/notifications';
import { usePlanLimits } from '@/hooks/planLimits';
import { useHistory, useWorkspaceId } from '@/hooks/router';

import { useWorkspaceQuery } from '../../../workspace/queries';

import {
  Action,
  Body,
  ConnectedEmail,
  Container,
  Description,
  Details,
  EmailTooltip,
  Icon,
  LinkButton,
  Title,
} from './ui';

export const ProfileSettingsGmail = () => {
  return (
    <DSContentBlock>
      <DSContentBlock.Title>Email Integration</DSContentBlock.Title>
      <DSContentBlock.Content>
        <GmailElement />
      </DSContentBlock.Content>
    </DSContentBlock>
  );
};

const GmailElement = () => {
  const amplitude = getAmplitudeClient();
  const posthog = usePostHog();
  const location = useLocation();
  const history = useHistory();
  const workspaceId = useWorkspaceId();
  const { addNotification } = useNotifications();
  const { data: workspace } = useWorkspaceQuery({ workspaceId: workspaceId });
  const { data: meQuery } = useMeQuery();
  const {
    mutateAsync: enableGmailCallbackMutation,
    isPending: gmailCallbackMutationLoading,
  } = useEnableGmailCallbackMutation({
    onSettled: () => history.replace(`/admin/${workspaceId}/settings/profile`),
  });

  const [
    openRemoveGmailIntegrationConfirmModal,
    setRemoveGmailIntegrationConfirmModal,
  ] = useState(false);
  const [openDeleteGmailDataConfirmModal, setOpenDeleteGmailDataConfirmModal] =
    useState(false);

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const canActivateEmail = useMemo(() => workspace?.gmailEnabled, [workspace]);
  const canAccessFeature = usePlanLimits();
  const canAccessGmail = canAccessFeature('gmail_integration');
  const gmailEnabled =
    meQuery?.gmailStatus === 'enabled' && workspace?.gmailEnabled;

  const enableGmailMutation = useEnableGmailMutation({
    onSuccess: (result: string) => result && (window.location.href = result),
  });
  const deleteGmailIntegrationMutation = useDeleteGmailIntegrationMutation({
    onSuccess: () => {
      setRemoveGmailIntegrationConfirmModal(false);
    },
  });
  const deleteGmailDataMutation = useDeleteGmailDataMutation({
    onSuccess: () => {
      setOpenDeleteGmailDataConfirmModal(false);
    },
  });

  useEffect(() => {
    if (searchParams.entries().next().done) return;

    const error = searchParams.get('error');
    if (error) {
      addNotification({ type: 'gmail_auth_error' });
      Sentry.captureException(new Error(`Gmail auth error: ${error}`));
      history.replace(`/admin/${workspaceId}/settings/profile`);
      return;
    }

    const code = searchParams.get('code');
    const scope = searchParams.get('scope');
    const state = searchParams.get('state');

    if (code && scope && state) {
      enableGmailCallbackMutation(searchParams.toString());
    } else {
      addNotification({ type: 'gmail_auth_error' });
      Sentry.captureException(
        new Error(
          `Gmail auth error: missing callback query params: ${[
            code,
            scope,
            state,
          ]
            .filter(Boolean)
            .join(', ')}`
        )
      );
      history.replace(`/admin/${workspaceId}/settings/profile`);
      return;
    }
  }, [
    searchParams,
    addNotification,
    enableGmailCallbackMutation,
    history,
    workspaceId,
  ]);

  return (
    <>
      <Container>
        <Icon>
          <GmailIcon />
        </Icon>
        <Body>
          <Title>Gmail</Title>

          <Description>
            Connect to Gmail to send and receive emails in Toggl Hire. View all
            communication with a candidate in one place.
          </Description>
        </Body>
        {gmailEnabled && canAccessGmail && (
          <ConnectedEmail email={meQuery?.connectedGmailEmail} />
        )}
        <Action>
          <DSButton
            variant="secondary"
            data-test-email-tooltip={
              !canActivateEmail && canAccessGmail
                ? ''
                : !canAccessGmail && !canActivateEmail
                  ? 'You need to upgrade your plan to enable the Gmail integration'
                  : null
            }
            data-test-email-tooltip-at={
              !canActivateEmail || !canAccessGmail ? 'left' : null
            }
            disabled={!canActivateEmail || !canAccessGmail}
            onClick={() => {
              gmailEnabled
                ? setRemoveGmailIntegrationConfirmModal(true)
                : enableGmailMutation.mutate();

              amplitude?.logEvent(
                `integrations/gmail integration - user ${gmailEnabled ? 'removed' : 'connected '}`,
                {
                  'workspace id': workspaceId,
                  'user id': meQuery?.id,
                }
              );
              posthog?.capture(
                `hire_admin_app:integrations/gmail integration - user ${gmailEnabled ? 'removed' : 'connected '}`,
                {
                  'workspace id': workspaceId,
                  'user id': meQuery?.id,
                }
              );
            }}
          >
            {workspace?.gmailEnabled && meQuery?.gmailStatus === 'enabled'
              ? 'Remove'
              : gmailCallbackMutationLoading
                ? 'Connecting...'
                : 'Connect'}
          </DSButton>
        </Action>

        {meQuery?.hasGmailData && gmailEnabled && (
          <Details>
            <LinkButton
              type="button"
              disabled={deleteGmailDataMutation.isPending}
              onClick={() => setOpenDeleteGmailDataConfirmModal(true)}
            >
              Delete Gmail user data
            </LinkButton>
          </Details>
        )}
      </Container>

      <ConfirmModal
        open={openRemoveGmailIntegrationConfirmModal}
        action="Remove"
        onConfirm={() => {
          deleteGmailIntegrationMutation.mutate();
        }}
        onClose={() => setRemoveGmailIntegrationConfirmModal(false)}
        title="Remove your Gmail account"
        footerVariant="destructive-primary"
      >
        <p>Are you sure you want to remove access to your Gmail account?</p>
        <p>
          You will no longer be able to contact candidates using your email
          address.
        </p>
      </ConfirmModal>

      <ConfirmModal
        open={openDeleteGmailDataConfirmModal}
        action="Delete"
        onConfirm={() => {
          deleteGmailDataMutation.mutate();
        }}
        onClose={() => setOpenDeleteGmailDataConfirmModal(false)}
        title="Delete Gmail user data"
        footerVariant="destructive-primary"
      >
        <p>Are you sure you want to delete your Gmail user data?</p>
        <p>
          This will remove Hire’s access to your Gmail account and delete any
          messages that you have synced. You can read more{' '}
          <Link
            href="https://support.hire.toggl.com/en/articles/10103491‒gmail"
            target="_blank"
          >
            here
          </Link>
          .
        </p>
      </ConfirmModal>
      <EmailTooltip />
    </>
  );
};

const Link = styled.a`
  cursor: pointer;
  text-decoration: underline;
  color: ${(props) => props.theme.typography.colorPrimary};

  &:hover {
    font-weight: 500;
  }
`;
