import * as React from 'react';

import { retryableLazyImport } from './lazy-loader';

export const SignUpPage = React.lazy(() =>
  retryableLazyImport(() => import('@/pages/authentication/signup-page'))
);

export const ForgottenPasswordPage = React.lazy(() =>
  retryableLazyImport(
    () => import('@/pages/authentication/forgotten-password-page')
  )
);

export const ResetPasswordPage = React.lazy(() =>
  retryableLazyImport(
    () => import('@/pages/authentication/reset-password-page')
  )
);
