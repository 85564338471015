import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { DSButton, DSField, DSTestLandingPage } from '@hundred5/design-system';
import { Form, Formik, FormikHelpers } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { mixed, object, string } from 'yup';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import {
  FormikInputField,
  FormikRadioField,
  PromptIfDirty,
} from '@/features/common';
import {
  createEditorState,
  CustomTextEditor,
  getEditorMarkdown,
} from '@/features/common/components/custom-text-editor';
import { DASHBOARD_JOB_OPENINGS_QUERY_KEY } from '@/features/dashboard';
import {
  TWorkplaceType,
  useJobOpeningQuery,
  useUpdateJobOpeningMutation,
} from '@/features/job-opening';
import { useNotifications } from '@/features/notifications';
import { useJobOpeningPermission } from '@/features/permissions';
import {
  useUpdateWorkspaceMutation,
  useWorkspace,
  WORKSPACE_STATS_QUERY_KEY,
} from '@/features/workspace';
import { queryClient } from '@/utils/reactQuery';

import { Logo, TitleImage } from './ui';

type TInitialValues = {
  titleImageUrl: string;
  titleImagePosition: { x: number; y: number };
  logoUrl: string;
  jobTitle: string;
  workplaceType: TWorkplaceType | null;
  jobDescription: string;
};

const validationSchema = object().shape({
  jobTitle: string().required('Please provide a job title!'),
  jobDescription: string()
    .required('Please provide a job description!')
    .max(
      6000,
      'Character limit reached. Please try shortening the page content!'
    ),
  workplaceType: mixed().required('Please choose a workplace type!'),
});

export function JobOpeningDescription() {
  const { addNotification } = useNotifications();
  const canUpdate = useJobOpeningPermission()('job opening', 'update');
  const amplitude = getAmplitudeClient();
  const posthog = usePostHog();
  const { data: jobOpening } = useJobOpeningQuery();
  const workspace = useWorkspace();

  const initialValuesRef = useRef<null | TInitialValues>(null);
  const [editorState, setEditorState] = useState(createEditorState(''));

  const updateWorkspaceMutation = useUpdateWorkspaceMutation({});
  const jobOpeningMutation = useUpdateJobOpeningMutation({
    jobOpeningId: jobOpening?.id!,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: DASHBOARD_JOB_OPENINGS_QUERY_KEY,
      });
      queryClient.invalidateQueries({ queryKey: WORKSPACE_STATS_QUERY_KEY });
    },
  });

  // Set the initial values only once, when the form is first loaded
  useEffect(() => {
    if (jobOpening && workspace && !initialValuesRef.current) {
      initialValuesRef.current = {
        titleImageUrl: jobOpening.titleImageUrl ?? '',
        titleImagePosition: jobOpening.titleImagePosition,
        logoUrl: workspace.logoUrl,
        jobTitle: jobOpening.name,
        workplaceType: jobOpening.workplaceType,
        jobDescription: jobOpening.description,
      };

      setEditorState(createEditorState(jobOpening.description));
    }
  }, [jobOpening, workspace]);

  if (!workspace || !jobOpening || !initialValuesRef.current) {
    return null;
  }

  const handleSubmit = async (values: any, helpers: FormikHelpers<any>) => {
    await jobOpeningMutation.mutateAsync({
      jobOpeningId: jobOpening.id,
      settings: {
        name: values.jobTitle,
        description: values.jobDescription,
        workplaceType: values.workplaceType,
        titleImageUrl: values.titleImageUrl,
        titleImagePosition: values.titleImagePosition,
      },
    });

    const updatedWorkspace = { ...workspace, logoUrl: values.logoUrl };
    await updateWorkspaceMutation.mutateAsync({
      workspaceId: workspace.id,
      workspace: updatedWorkspace,
    });

    // Reset the form and update initial values
    initialValuesRef.current = values;
    helpers.resetForm({ values });

    amplitude?.logEvent('job description/save', {
      'workspace id': workspace.id,
      'job opening id': jobOpening.id,
    });
    posthog?.capture('hire_admin_app:job description/save', {
      'workspace id': workspace.id,
      'job opening id': jobOpening.id,
    });

    addNotification({ type: 'saved' });
  };

  return (
    <Formik
      initialValues={initialValuesRef.current}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, touched, errors, dirty, isSubmitting, setFieldValue }) => (
        <StyledForm>
          <PromptIfDirty />

          <Container>
            <SaveButton
              type="submit"
              disabled={!canUpdate || !dirty || isSubmitting}
              data-rh={!canUpdate ? 'Only admins can save changes' : null}
            >
              {isSubmitting ? 'Saving...' : 'Save changes'}
            </SaveButton>

            <DSTestLandingPage embedded>
              <TitleImage
                editable
                url={values.titleImageUrl}
                position={values.titleImagePosition}
                onUpdate={(url) => {
                  setFieldValue('titleImageUrl', url);
                }}
                onReposition={(position) => {
                  setFieldValue('titleImagePosition', position);
                }}
              />

              <DSTestLandingPage.Header>
                <Logo
                  editable
                  url={values.logoUrl}
                  onUpdate={(url) => {
                    setFieldValue('logoUrl', url);
                  }}
                />
              </DSTestLandingPage.Header>

              <DSTestLandingPage.Main>
                <FormikInputField
                  name="jobTitle"
                  label="Job title"
                  placeholder="e.g. Frontend Developer"
                  required
                />

                <SettingsSection>
                  <SettingsTitle>Location</SettingsTitle>
                  <DSField
                    required
                    label="Workplace"
                    for="workplaceType"
                    error={
                      touched.workplaceType && !!errors.workplaceType ? (
                        <>{errors.workplaceType}</>
                      ) : null
                    }
                  >
                    <OptionsWrapper>
                      <FormikRadioField
                        name="workplaceType"
                        value={TWorkplaceType.Remote}
                        label={
                          <LabelWrapper>
                            Remote
                            <Info>Employees work from home</Info>
                          </LabelWrapper>
                        }
                      />
                      <FormikRadioField
                        name="workplaceType"
                        value={TWorkplaceType.Hybrid}
                        label={
                          <LabelWrapper>
                            Hybrid
                            <Info>
                              Employees work from both office and home
                            </Info>
                          </LabelWrapper>
                        }
                      />
                      <FormikRadioField
                        name="workplaceType"
                        value={TWorkplaceType.Onsite}
                        label={
                          <LabelWrapper>
                            On-site
                            <Info>Employees work from an office</Info>
                          </LabelWrapper>
                        }
                      />
                    </OptionsWrapper>
                  </DSField>
                </SettingsSection>

                <SettingsSection>
                  <SettingsTitle>Description</SettingsTitle>
                  <DSField
                    required
                    label="About the job"
                    for="jobDescription"
                    error={
                      touched.jobDescription && !!errors.jobDescription ? (
                        <>{errors.jobDescription}</>
                      ) : null
                    }
                  >
                    <CustomTextEditor
                      state={editorState}
                      onChange={(state) => {
                        setFieldValue(
                          'jobDescription',
                          getEditorMarkdown(state)
                        );
                        setEditorState(state);
                      }}
                      error={touched.jobDescription && !!errors.jobDescription}
                    />
                  </DSField>
                </SettingsSection>
              </DSTestLandingPage.Main>
            </DSTestLandingPage>

            <SaveButton
              type="submit"
              disabled={!canUpdate || !dirty || isSubmitting}
              data-rh={!canUpdate ? 'Only admins can save changes' : null}
            >
              {isSubmitting ? 'Saving...' : 'Save changes'}
            </SaveButton>
          </Container>
        </StyledForm>
      )}
    </Formik>
  );
}

const StyledForm = styled(Form)`
  display: flex;
  flex: 1;
`;

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.purple[10]};
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 28px;
  flex: 1;
`;

const SaveButton = styled(DSButton)`
  float: right;
`;

const SettingsSection = styled.section`
  display: grid;
  gap: 16px;
`;

const SettingsTitle = styled.h2`
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.typography.colorPrimary};
`;

const OptionsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: '1fr 1fr 1fr';
  gap: 24px;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Info = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme.typography.colorSecondary};
`;
