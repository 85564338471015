import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import {
  FullCustomizationIllustration,
  GenericIllustration,
  JobsIllustration,
  UnlimitedSkillsTestsIllustration,
} from './ui';

export const UPSELL_MODAL_COPIES = {
  generic: {
    trial: {
      title: 'Try Starter plan for free',
      description:
        'Experience greater recruitment efficiency, an improved candidate journey, and seamless hiring workflows.',
      listTitle: 'You can:',
      list: [
        {
          icon: regular('briefcase-blank'),
          text: 'Hire faster with 3 job openings',
        },
        {
          icon: solid('infinity'),
          text: 'Add unlimited, fully-customizable tests',
        },
        {
          icon: regular('message-bot'),
          text: 'Save time with async interviews, automation and AI',
        },
      ],
    },
    starter: {
      title: 'Upgrade to Starter',
      description:
        'Experience greater recruitment efficiency, an improved candidate journey, and seamless hiring workflows.',
      listTitle: 'You can:',
      list: [
        {
          icon: regular('briefcase-blank'),
          text: 'Hire faster with 3 job openings',
        },
        {
          icon: solid('infinity'),
          text: 'Add unlimited, fully-customizable tests',
        },
        {
          icon: regular('message-bot'),
          text: 'Save time with async interviews, automation and AI',
        },
      ],
    },
    premium: {
      title: 'Upgrade to Premium',
      description:
        'Upgrade to Premium for expanded capabilities, enhanced customization, and a dedicated Customer Support Manager. ',
      listTitle: 'You can:',
      list: [
        {
          icon: ['custom', 'custom-regular-briefcase-blank-circle-plus'],
          text: 'Hire faster with unlimited job openings',
        },
        {
          icon: regular('pencil'),
          text: 'Assess candidates with homework assignments',
        },
        {
          icon: regular('key'),
          text: 'Manage access with team permissions and SSO',
        },
      ],
    },
    illustration: GenericIllustration,
  },
  unlimitedSkillsTests: {
    trial: {
      title: 'Try Starter plan for free',
      description:
        'Test candidates more effectively with unlimited, fully customizable skills assessments.',
      listTitle: 'You can:',
      list: [
        {
          icon: regular('book-open'),
          text: 'Access the full 20k+ skills question library',
        },
        {
          icon: regular('message-bot'),
          text: 'Save time with async interviews, automation and AI',
        },
        {
          icon: regular('square-check'),
          text: 'Add unlimited, fully customizable skills tests',
        },
      ],
    },
    starter: {
      title: 'Upgrade to Starter',
      description:
        'Test candidates more effectively with unlimited, fully customizable skills assessments.',
      listTitle: 'Other benefits:',
      list: [
        {
          icon: regular('book-open'),
          text: 'Access the full 20k+ skills question library',
        },
        {
          icon: regular('message-bot'),
          text: 'Save time with async interviews, automation and AI',
        },
        {
          icon: regular('square-check'),
          text: 'Add unlimited, fully customizable skills tests',
        },
      ],
    },
    premium: {
      title: '',
      description: '',
      listTitle: '',
      list: [{ icon: null, text: '' }],
    },
    illustration: UnlimitedSkillsTestsIllustration,
  },
  jobs: {
    trial: {
      title: 'Try Starter for free',
      description:
        'Expand your recruitment efforts and hire more quickly with 3 active job openings. ',
      listTitle: 'You can also:',
      list: [
        {
          icon: regular('square-check'),
          text: 'Add unlimited, fully-customizable tests',
        },
        {
          icon: regular('message-bot'),
          text: 'Save time with async interviews, automation and AI',
        },
        {
          icon: regular('envelope'),
          text: 'Sync your communication with the candidate',
        },
      ],
    },
    starter: {
      title: 'Upgrade to Starter',
      description:
        'Expand your recruitment efforts and hire more quickly with 3 active job openings. ',
      listTitle: 'Other benefits:',
      list: [
        {
          icon: regular('square-check'),
          text: 'Add unlimited, fully-customizable tests',
        },
        {
          icon: regular('message-bot'),
          text: 'Save time with async interviews, automation and AI',
        },
        {
          icon: regular('envelope'),
          text: 'Sync your communication with the candidate',
        },
      ],
    },
    premium: {
      title: 'Upgrade to Premium',
      description:
        'Building a bigger team? We have the tools you need. Upgrade to Premium to hire more quickly with unlimited job openings. ',
      listTitle: 'Other benefits:',
      list: [
        {
          icon: regular('square-check'),
          text: 'Unlimited skills assessments',
        },
        {
          icon: regular('pencil'),
          text: 'Test job-role fit with homework assignments',
        },
        {
          icon: regular('key'),
          text: 'Custom roles and permissions',
        },
      ],
    },
    illustration: JobsIllustration,
  },
  fullCustomization: {
    trial: {
      title: 'Try Starter for free',
      description:
        'Create unlimited, fully customizable tests to better assess candidates for job-role fit and future performance.',
      listTitle: 'Other benefits:',
      list: [
        {
          icon: regular('briefcase-blank'),
          text: 'Hire faster with 3 job openings',
        },
        {
          icon: regular('message-bot'),
          text: 'Save time with async interviews, automation and AI',
        },
        {
          icon: regular('triangle-exclamation'),
          text: 'Hire confidently with anti-cheating measures',
        },
      ],
    },
    starter: {
      title: 'Upgrade to Starter',
      description:
        'Create unlimited, fully customizable tests to better assess candidates for job-role fit and future performance.',
      listTitle: 'Other benefits:',
      list: [
        {
          icon: regular('briefcase-blank'),
          text: 'Hire faster with 3 job openings',
        },
        {
          icon: regular('message-bot'),
          text: 'Save time with async interviews, automation and AI',
        },
        {
          icon: regular('triangle-exclamation'),
          text: 'Hire confidently with anti-cheating measures',
        },
      ],
    },
    premium: {
      title: '',
      description: '',
      listTitle: '',
      list: [{ icon: null, text: '' }],
    },
    illustration: FullCustomizationIllustration,
  },
  changePlan: {
    premium: {
      title: 'We’ve changed our plans',
      description:
        'Move to our brand new Premium plan to get more from Toggl Hire!',
      listTitle: 'You can:',
      list: [
        {
          icon: ['custom', 'custom-regular-briefcase-blank-circle-plus'],
          text: 'Hire faster with unlimited job openings',
        },
        {
          icon: regular('pencil'),
          text: 'Assess candidates with homework assignments',
        },
        {
          icon: regular('key'),
          text: 'Manage access with team permissions',
        },
      ],
    },
    illustration: GenericIllustration,
  },
  automation: {
    premium: {
      title: 'Unlock more automations',
      description:
        'Benefit from the full range of automations on our Premium plan',
      listTitle: 'You can also:',
      list: [
        {
          icon: ['custom', 'custom-regular-briefcase-blank-circle-plus'],
          text: 'Hire faster with unlimited job openings',
        },
        {
          icon: regular('pencil'),
          text: 'Assess candidates with homework assignments',
        },
        {
          icon: regular('key'),
          text: 'Manage access with team permissions',
        },
      ],
    },
    illustration: GenericIllustration,
  },
};
