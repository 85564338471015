import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';
import { format } from 'date-fns/format';
import { FormikProps, FormikValues } from 'formik';
import { capitalize } from 'lodash';

import { ClientError } from '@/errors';
import {
  BillingPlan,
  billingPlans,
  BillingPreview,
  CardInfoChallengeError,
  useBillingInfoQuery,
  useCardInfoQuery,
  useChangeSubscriptionPlanMutation,
  useChangeSubscriptionPlanPreview,
  useFreeTrial,
  useRemoveBillingInfoMutation,
  useSubscribeModal,
} from '@/features/billing';
import {
  CouponForm,
  Price,
} from '@/features/billing/components/subscribe-modal/ui';
import { BillingInfoForm } from '@/features/billing/components/subscribe-modal/ui/billing-info-form';
import { Loader } from '@/features/common';
import { useNotifications } from '@/features/notifications';
import { useFinishWorkspaceFreeTrialMutation } from '@/features/workspace';
import { trackSwitchPlan } from '@/googleTagManager';
import { useApiError } from '@/hooks/api';
import { useWorkspaceId } from '@/hooks/router';

import bikeForward from '../assets/bike-forward.svg';

import { Content, Header, Illustration } from '.';

interface IUpgradeProps {
  plan: BillingPlan | undefined;
  onGoBack?: () => void;
}

export const Upgrade = ({ plan, onGoBack }: IUpgradeProps) => {
  const [reviewChanges, setReviewChanges] = useState(false);
  const { lastActiveTrial } = useFreeTrial();
  const workspaceId = useWorkspaceId();
  const billingPlan = plan && billingPlans[plan];
  const formRef = useRef<FormikProps<FormikValues>>();

  const { addNotification } = useNotifications();
  const handleApiError = useApiError();
  const { coupon, preview, challengeRequired } = useSubscribeModal();
  const { data: cardInfo, isPending: isPendingCardInfo } = useCardInfoQuery();
  const { data: billingInfo, isPending: isPendingBilling } =
    useBillingInfoQuery();
  const changeSubscriptionPlanMutation = useChangeSubscriptionPlanMutation();
  const changeSubscriptionPreview = useChangeSubscriptionPlanPreview();
  const { mutate: removeBillingInfo, isPending: isRemovingBillingInfo } =
    useRemoveBillingInfoMutation();
  const finishDowngradeTrial = useFinishWorkspaceFreeTrialMutation();

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const initializing = useMemo(
    () => isPendingBilling || isPendingCardInfo,
    [isPendingBilling, isPendingCardInfo]
  );

  const handleSubscribe = useCallback(
    async (
      selectedPlan: BillingPlan,
      preview: BillingPreview | null,
      challengeToken?: string,
      couponCode?: string
    ) => {
      await changeSubscriptionPlanMutation.mutateAsync(
        {
          plan: selectedPlan,
          challengeToken,
          couponCode,
        },
        {
          onSuccess: async () => {
            if (preview != null && preview.chargeInvoice != null) {
              const total = preview.chargeInvoice.totalInCents;
              trackSwitchPlan(selectedPlan, total);
            }
            if (lastActiveTrial) {
              await finishDowngradeTrial.mutate({
                workspaceId,
                trialId: lastActiveTrial.id,
              });
            }

            addNotification({ type: 'plan_updated' });
          },

          onError: (error) => {
            if (error instanceof CardInfoChallengeError) {
              challengeRequired(error.token);
            } else if (
              error instanceof ClientError &&
              error.type === 'InvalidCouponCode'
            ) {
              addNotification({ type: 'coupon_limit_reached' });
            } else {
              handleApiError(error);
            }
          },
        }
      );
    },
    [
      challengeRequired,
      changeSubscriptionPlanMutation,
      handleApiError,
      addNotification,
      finishDowngradeTrial,
      lastActiveTrial,
      workspaceId,
    ]
  );

  const needsBillingInfo = useMemo(
    () => cardInfo == null || billingInfo == null,
    [cardInfo, billingInfo]
  );

  const showDiscount = !!coupon && !!billingPlan?.price;
  const discountedPrice =
    coupon?.discount.type === 'percent'
      ? (billingPlan?.price! * (100 - coupon?.discount.percent)) / 100
      : coupon?.discount.type === 'dollars' &&
        (billingPlan?.type === 'Annually'
          ? (billingPlan?.price! - coupon?.discount.amount! / 1200).toFixed(2)
          : billingPlan?.type === 'Monthly' &&
            billingPlan?.price! - coupon?.discount.amount! / 100);

  const priceData: {
    subtotal: number;
    discountedPrice: number;
    discount: String;
    price: Number;
  } = useMemo(
    () =>
      billingPlan?.type === 'Annually'
        ? {
            subtotal: billingPlan.price * 12,
            discountedPrice: Number(discountedPrice) * 12,
            discount: (
              Number(discountedPrice) -
              billingPlan?.price * 12
            ).toFixed(2),
            price: Number(
              discountedPrice ? discountedPrice : billingPlan.price
            ),
          }
        : {
            subtotal: billingPlan?.price ? billingPlan.price : 0,
            discountedPrice: Number(discountedPrice),
            discount: (billingPlan?.price! - Number(discountedPrice)).toFixed(
              2
            ),
            price: Number(
              discountedPrice ? discountedPrice : (billingPlan?.price ?? 0)
            ),
          },
    [billingPlan, discountedPrice]
  );

  return (
    <>
      <Content>
        <Header>
          <h1>
            Subscribe to {capitalize(billingPlan?.name)}
            <PlanTypeTag>{billingPlan?.type}</PlanTypeTag>
          </h1>

          {!needsBillingInfo && (
            <Note>
              To make changes to your credit card details, first remove the
              existing card.
              {!reviewChanges && (
                <CardDetailsInfo onClick={() => setReviewChanges(true)}>
                  Edit Card Details
                </CardDetailsInfo>
              )}
            </Note>
          )}
        </Header>

        {initializing ? (
          <LoadingContainer>
            <Loader />
          </LoadingContainer>
        ) : (
          <>
            <div>
              {(needsBillingInfo || reviewChanges) && (
                <BillingWrapper>
                  <BillingInfoForm
                    formRef={formRef}
                    onFinish={() =>
                      changeSubscriptionPreview({
                        plan: plan!,
                        couponCode: coupon?.code,
                      })
                    }
                  />
                </BillingWrapper>
              )}

              <Price plan={plan!} coupon={coupon} variant="downgrade" />

              <CouponForm plan={plan ?? null} />

              {!(needsBillingInfo || reviewChanges) && (
                <CompletePurchase>
                  <PurchaseText>
                    {billingPlan?.type === 'Annually'
                      ? `You will be charged $${(
                          Number(priceData.price) * 12
                        ).toFixed(2)} today for 365 days. Your next
                        payment of $${(Number(priceData.price) * 12).toFixed(
                          2
                        )} will be due ${format(
                          new Date().setFullYear(new Date().getFullYear() + 1),
                          'MMM d, yyyy'
                        )}. You can cancel
                        anytime.`
                      : `You will be charged $${
                          priceData.price
                        } today for 1 month. Your next payment of $${
                          priceData.price
                        } will be due ${format(
                          new Date().setMonth(new Date().getMonth() + 1),
                          'MMM d, yyyy'
                        )}. You can cancel anytime.`}
                  </PurchaseText>
                  <PriceInfo>
                    <span>Subtotal: ${priceData.subtotal}</span>
                    {showDiscount && (
                      <span>
                        Used credit: -$
                        {(
                          priceData.subtotal - priceData.discountedPrice
                        ).toFixed(2)}
                      </span>
                    )}
                    <span>
                      <b>
                        Total: $
                        {(priceData.discountedPrice !== 0
                          ? priceData.discountedPrice
                          : priceData.subtotal
                        ).toFixed(2)}
                      </b>
                    </span>
                  </PriceInfo>
                </CompletePurchase>
              )}
            </div>

            <Footer>
              <FooterLeft>
                {reviewChanges && !needsBillingInfo && (
                  <DSButton
                    variant="tertiary"
                    disabled={isRemovingBillingInfo}
                    onClick={() => {
                      removeBillingInfo();
                    }}
                  >
                    {!isRemovingBillingInfo
                      ? 'Remove card'
                      : 'Removing card...'}
                  </DSButton>
                )}
              </FooterLeft>
              <FooterRight>
                <DSButton variant="secondary" onClick={onGoBack}>
                  Go back
                </DSButton>

                {needsBillingInfo || reviewChanges ? (
                  <DSButton
                    type="submit"
                    variant="primary-purple"
                    onClick={() => handleSubmit()}
                    disabled={
                      formRef.current?.isSubmitting || !needsBillingInfo
                    }
                  >
                    {!formRef.current?.isSubmitting
                      ? 'Review purchase'
                      : 'Saving...'}
                  </DSButton>
                ) : (
                  <DSButton
                    type="submit"
                    variant="primary-purple"
                    disabled={changeSubscriptionPlanMutation.isPending}
                    onClick={() =>
                      handleSubscribe(plan!, preview, undefined, coupon?.code)
                    }
                  >
                    {changeSubscriptionPlanMutation.isPending
                      ? 'Completing...'
                      : 'Complete purchase'}
                  </DSButton>
                )}
              </FooterRight>
            </Footer>
          </>
        )}
      </Content>

      <Illustration src={bikeForward} alt="" centered />
    </>
  );
};

const LoadingContainer = styled.div`
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    height: 75px;
    width: auto;
  }
`;

const PlanTypeTag = styled.div`
  color: ${(props) => props.theme.typography.colorPrimary};
  background: ${(props) => props.theme.colors.yellow[50]};
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  padding: 0 12px;
  border-radius: 8px;
`;

const Note = styled.div`
  padding: 6px 12px;
  background: ${({ theme }) => theme.colors.purple[5]};
  border-radius: 8px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.purple[100]};
  margin-top: 16px;
`;

const CardDetailsInfo = styled.div`
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
`;

const BillingWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.purple[10]};
  padding-bottom: 36px;
  margin-bottom: 16px;
  width: 100%;
`;

const CompletePurchase = styled.div`
  margin-top: 36px;
  padding-top: 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.purple[5]};
  display: grid;
  grid-template-columns: auto max-content;
  gap: 24px;
`;

const PurchaseText = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.purple[100]};
`;

const PriceInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.purple[100]};
`;

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 36px;
  gap: 8px;
  flex-flow: wrap;
`;

const FooterLeft = styled.div`
  @media all and (((min-width: 769px) and (max-width: 994px)) or (max-width: 540px)) {
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }

  :empty {
    display: none;
  }
`;

const FooterRight = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;
  justify-content: flex-end;

  @media all and (max-width: 420px) {
    flex-wrap: wrap;
  }
`;
