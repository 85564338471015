import { keepPreviousData, QueryClient } from '@tanstack/react-query';

const SECOND = 1000;
const MINUTE = 60 * SECOND;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      staleTime: MINUTE,
      gcTime: 5 * MINUTE,
      refetchOnMount: true,
      retryOnMount: false,
      placeholderData: keepPreviousData,
    },
  },
});
