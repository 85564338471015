import { useQuery } from '@tanstack/react-query';

import { fetchSkills, IFetchSkillsParams } from '../api';
import { ISkill } from '../types';

export const SKILLS_QUERY_KEY = ['skills'];

interface IUseSkillsQuery {
  requestParams?: IFetchSkillsParams;
}

export const useSkillsQuery = ({ requestParams }: IUseSkillsQuery) =>
  useQuery<ISkill[]>({
    queryKey: [...SKILLS_QUERY_KEY, JSON.stringify(requestParams)],
    queryFn: () => fetchSkills(requestParams),
    staleTime: 60 * 1000,
  });
