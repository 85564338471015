import {
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { TId } from '@/features/common';
import {
  IJobOpeningATSIntegrationSettings,
  TATSIntegrationType,
  addATSIntegration,
  createJobOpeningATSIntegrationSettings,
  deleteATSIntegration,
  deleteJobOpeningATSIntegrationSettings,
  fetchATSIntegrations,
  fetchATSJobOpenings,
  fetchJobOpeningATSIntegrationSettings,
  updateJobOpeningATSIntegrationSettings,
} from '@/features/integrations';
import { useNotifications } from '@/features/notifications';
import {
  useJobOpeningId,
  useJobOpeningIdOrNull,
  useWorkspaceId,
} from '@/hooks/router';

const ATS_INTEGRATION_KEY = ['ats-integration'];

const JOB_OPENING_ATS_INTEGRATION_SETTINGS_KEY = [
  'job-opening-ats-integration-settings',
];

const ATS_JOB_OPENINGS_KEY = ['ats-job-openings'];

export const useATSIntegrationsQuery = () => {
  const workspaceId = useWorkspaceId();
  const queryClient = useQueryClient();

  queryClient.setMutationDefaults(ATS_INTEGRATION_KEY, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [...ATS_INTEGRATION_KEY, workspaceId],
      });
      queryClient.invalidateQueries({
        queryKey: [...JOB_OPENING_ATS_INTEGRATION_SETTINGS_KEY],
      });
    },
  });

  return useQuery({
    queryKey: [...ATS_INTEGRATION_KEY, workspaceId],
    queryFn: () => fetchATSIntegrations({ workspaceId }),
  });
};

export const useAddATSIntegrationMutation = (options?: UseMutationOptions) => {
  const workspaceId = useWorkspaceId();
  const jobOpeningId = useJobOpeningIdOrNull();

  return useMutation<
    string | null,
    Error,
    {
      integrationType: TATSIntegrationType;
    }
  >({
    mutationFn: (atsIntegrationType: TATSIntegrationType) =>
      addATSIntegration({
        workspaceId,
        atsIntegrationType,
        jobOpeningId,
      }),

    mutationKey: ATS_INTEGRATION_KEY,
    ...(options as any),
  });
};

export const useDeleteATSIntegrationMutation = () => {
  return useMutation({
    mutationFn: deleteATSIntegration,
    mutationKey: ATS_INTEGRATION_KEY,
  });
};

export const useJobOpeningATSIntegrationSettingsQuery = () => {
  const workspaceId = useWorkspaceId();
  const jobOpeningId = useJobOpeningId();
  const queryClient = useQueryClient();
  const { addNotification } = useNotifications();

  queryClient.setMutationDefaults(JOB_OPENING_ATS_INTEGRATION_SETTINGS_KEY, {
    onSuccess: () => {
      addNotification({ type: 'saved' });
      queryClient.invalidateQueries({
        queryKey: [...JOB_OPENING_ATS_INTEGRATION_SETTINGS_KEY, jobOpeningId],
      });
      queryClient.invalidateQueries({
        queryKey: [...ATS_INTEGRATION_KEY, workspaceId],
      });
    },
  });

  return useQuery({
    queryKey: [...JOB_OPENING_ATS_INTEGRATION_SETTINGS_KEY, jobOpeningId],
    queryFn: () => fetchJobOpeningATSIntegrationSettings({ jobOpeningId }),
  });
};

export const useCreateJobOpeningATSIntegrationSettingsMutation = () => {
  const jobOpeningId = useJobOpeningId();

  return useMutation({
    mutationFn: (integration: IJobOpeningATSIntegrationSettings) =>
      createJobOpeningATSIntegrationSettings({ jobOpeningId, integration }),
    mutationKey: JOB_OPENING_ATS_INTEGRATION_SETTINGS_KEY,
  });
};

export const useUpdateJobOpeningATSIntegrationSettingsMutation = () => {
  const jobOpeningId = useJobOpeningId();

  return useMutation({
    mutationFn: (integration: IJobOpeningATSIntegrationSettings) =>
      updateJobOpeningATSIntegrationSettings({ jobOpeningId, integration }),
    mutationKey: JOB_OPENING_ATS_INTEGRATION_SETTINGS_KEY,
  });
};

export const useDeleteJobOpeningATSIntegrationSettingsMutation = () => {
  const jobOpeningId = useJobOpeningId();

  return useMutation({
    mutationFn: (integrationId: TId) =>
      deleteJobOpeningATSIntegrationSettings({ jobOpeningId, integrationId }),
    mutationKey: JOB_OPENING_ATS_INTEGRATION_SETTINGS_KEY,
  });
};

export const useATSJobOpeningsQuery = (
  params: {
    atsIntegrationType: TATSIntegrationType;
    atsIntegrationId: TId;
  },
  options?: { enabled?: boolean }
) =>
  useQuery({
    queryKey: [
      ...ATS_JOB_OPENINGS_KEY,
      params.atsIntegrationType,
      params.atsIntegrationId,
    ],
    queryFn: () => fetchATSJobOpenings(params),
    enabled: options?.enabled ?? true,
  });
