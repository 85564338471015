import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';

import { parseExistingTest } from '@/api/tests';
import { TId, useDocumentTitle, usePageLayout } from '@/features/common';
import { Loader } from '@/features/common/components/loader';
import {
  useJobOpeningQuery,
  useJobOpeningStatus,
} from '@/features/job-opening';
import {
  LandingPageSettings,
  ResultPageSettings,
  TestEditor,
  TestPageContext,
  TestPageHeader,
  TestSettings,
  useTestQuery,
  VideoIntroduction,
} from '@/features/test';
import { useWorkspace } from '@/features/workspace';
import { useHistory } from '@/hooks/router';

const TestPage = () => {
  const { url } = useRouteMatch();
  const { testId } = useParams<{ testId: TId }>();
  const history = useHistory();
  const pageLayout = usePageLayout();
  const workspace = useWorkspace();

  const {
    data: jobOpening,
    isPending: isJobOpeningLoading,
    isError: isErrorJobOpeningQuery,
  } = useJobOpeningQuery({
    enabled: !!workspace,
    retry: false,
  });

  useEffect(() => {
    if (isErrorJobOpeningQuery) {
      history.push(`/admin/${workspace!.id}/openings`);
    }
  }, [isErrorJobOpeningQuery, history, workspace]);

  const isJobOpeningClosed = useJobOpeningStatus({ data: jobOpening }).isClosed;

  const {
    data: test,
    isPending: isTestLoading,
    isError: isErrorTestQuery,
  } = useTestQuery(
    { testId },
    {
      enabled: !!workspace && !!jobOpening && !!testId,
      retry: false,
    }
  );

  useEffect(() => {
    if (isErrorTestQuery) {
      history.push(`/admin/${workspace!.id}/openings/${jobOpening!.id}`);
    }
  }, [isErrorTestQuery, history, workspace, jobOpening]);

  useDocumentTitle(test?.name);

  if (isJobOpeningLoading || isTestLoading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  if (!workspace || !jobOpening || !test) {
    history.push(`/admin`);
    return null;
  }

  if (isJobOpeningClosed) {
    history.push(`/admin/${workspace.id}/openings/${jobOpening.id}/pipeline`);
    return null;
  }

  return (
    <TestPageContext.Provider value={{ workspace, jobOpening, test }}>
      <TestPageContainer offsetTop={pageLayout.offsetTop}>
        <TestPageHeader />
        <Content>
          <Switch>
            <Route path={`${url}/settings`}>
              <TestSettings />
            </Route>

            <Route path={`${url}/questions`}>
              <TestEditor />
            </Route>

            <Route path={`${url}/landing-page`}>
              <LandingPageSettings testId={test.id} />
            </Route>

            <Route path={`${url}/results-page`}>
              <ResultPageSettings testId={test.id} />
            </Route>

            <Route path={`${url}/intro`}>
              {/* @ts-ignore TODO: temporary workaround for incompatible interfaces, this is to be removed when single settings screens are refactored */}
              <VideoIntroduction test={parseExistingTest(test)} />
            </Route>
          </Switch>
        </Content>
      </TestPageContainer>
    </TestPageContext.Provider>
  );
};

const TestPageContainer = styled.div<{ offsetTop: number }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: ${({ offsetTop }) => `calc(100svh - ${offsetTop}px)`};
`;
const LoaderWrapper = styled.div`
  padding: 24px;
  text-align: center;
`;
const Content = styled.div`
  flex: 1;
  padding: 24px;
  overflow: auto;
`;

export default TestPage;
