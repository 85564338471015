import {
  IconDefinition,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core';

export const regularBriefcaseBlankCirclePlus: IconDefinition = {
  prefix: 'custom' as IconPrefix,
  iconName: 'custom-regular-briefcase-blank-circle-plus' as IconName,
  icon: [
    640,
    512,
    [],
    '',
    'M448 96l-64 0c0-10.7 0-21.3 0-32c0-35.4-28.6-64-64-64c-42.7 0-85.3 0-128 0c-35.3 0-64 28.7-64 64c0 10.7 0 21.3 0 32L64 96C28.7 96 0 124.7 0 160c0 85.4 0 170.7 0 256c0 35.4 28.7 64 64 64c98.8 0 197.5 0 296.3 0c-11.8-14.3-21.4-30.5-28.3-48c-89.3 0-178.7 0-268 0c-8.8 0-16-7.2-16-16c0-85.4 0-170.7 0-256c0-8.8 7.2-16 16-16c128 0 256 0 384 0c8.8 0 16 7.2 16 16c0 11.6 0 23.3 0 34.9c10.4-1.9 21.1-2.9 32-2.9c5.4 0 10.7 .2 16 .7c0-10.9 0-21.8 0-32.7c0-35.3-28.7-64-64-64zM176 64c0-8.8 7.2-16 16-16c42.7 0 85.3 0 128 0c8.8 0 16 7.2 16 16c0 10.7 0 21.3 0 32c-53.3 0-106.7 0-160 0c0-10.7 0-21.3 0-32zM640 368c0 79.5-64.5 144-144 144s-144-64.5-144-144s64.5-144 144-144s144 64.5 144 144zM480 304l0 48c-16 0-32 0-48 0c-8.8 0-16 7.2-16 16s7.2 16 16 16c16 0 32 0 48 0l0 48c0 8.8 7.2 16 16 16s16-7.2 16-16c0-16 0-32 0-48c16 0 32 0 48 0c8.8 0 16-7.2 16-16s-7.2-16-16-16c-16 0-32 0-48 0c0-16 0-32 0-48c0-8.8-7.2-16-16-16s-16 7.2-16 16z',
  ],
};
