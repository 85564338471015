import { useQuery } from '@tanstack/react-query';

import { fetchTemplateCategories } from '../api';

export const TEMPLATE_CATEGORIES_QUERY_KEY = ['template_categories'];

export const useTemplateCategoriesQuery = (options?: { enabled?: boolean }) => {
  return useQuery({
    queryKey: [...TEMPLATE_CATEGORIES_QUERY_KEY],
    queryFn: () => fetchTemplateCategories(),
    enabled: options?.enabled ?? true,
  });
};
