import React from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useFormikContext } from 'formik';

import { FormikSelectField, Icon } from '@/features/common';
import { usePipelineCategoriesQuery } from '@/features/pipeline';
import { IAddTestToPipelineFormValues } from '@/features/test-library';

import { AddNewButton } from './add-new-job-button';

export const StageSelector = ({
  onAddNewStage,
}: {
  onAddNewStage: () => void;
}) => {
  const form = useFormikContext<IAddTestToPipelineFormValues>();
  const jobOpeningId = form.values.jobOpeningId;
  const jobOpeningQuery = usePipelineCategoriesQuery(
    { jobOpeningId },
    { enabled: !!jobOpeningId }
  );

  const pipelineCategoriesItems = !jobOpeningQuery.isPlaceholderData
    ? jobOpeningQuery.data?.length
      ? jobOpeningQuery.data
          .filter(
            (category) =>
              !category.testId && !category.interviewId && !category.isSystem
          )
          .map((category) => ({
            id: category.id,
            label: category.name,
            labelText: category.name,
          }))
      : []
    : [];

  return (
    <FormikSelectField
      name="pipelineStageId"
      items={pipelineCategoriesItems}
      disabled={!jobOpeningId}
      placeholder="Select stage"
      Footer={
        <AddNewButton onClick={() => onAddNewStage()}>
          <Icon icon={regular('plus')} />
          Add new stage
        </AddNewButton>
      }
    />
  );
};
