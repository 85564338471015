import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { PreferenceName, useMeQuery } from '@/features/me';

import { fetchPreferences, updatePreference } from '../api';
import { isUserInSupportMode } from '../utils/me';

export const PREFERENCES_KEY = ['preferences'];

export const usePreferencesQuery = (options?: { enabled?: boolean }) => {
  const me = useMeQuery();

  return useQuery({
    queryKey: PREFERENCES_KEY,
    queryFn: fetchPreferences,
    enabled: me.data?.id !== '0' && (options?.enabled ?? true), // avoid making the request in support mode
  });
};

export const usePreferenceMutation = () => {
  const queryClient = useQueryClient();
  const me = useMeQuery();
  const supportMode = me.isSuccess ? isUserInSupportMode(me.data) : true;

  return useMutation({
    mutationFn: ({ name, value }: { name: PreferenceName; value: any }) => {
      if (!supportMode) {
        return updatePreference({ name, value });
      } else {
        return Promise.resolve();
      }
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: PREFERENCES_KEY }),
  });
};
