import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { sortBy } from 'lodash';

import { checkClientError } from '@/errors';
import { TId } from '@/features/common';
import { DASHBOARD_JOB_OPENINGS_QUERY_KEY } from '@/features/dashboard';
import { JOB_OPENING_QUERY_KEY } from '@/features/job-opening';
import { useNotifications } from '@/features/notifications';
import {
  createPipelineCategory,
  deletePipelineCategory,
  fetchPipelineCategories,
  IPipelineCategory,
  updatePipelineCategory,
} from '@/features/pipeline';
import { useApiError } from '@/hooks/api';

export const PIPELINE_CATEGORIES_KEY = ['pipeline-categories'];

export const usePipelineCategoriesQuery = (
  params: { jobOpeningId: TId },
  options?: { enabled?: boolean }
) => {
  return useQuery<IPipelineCategory[]>({
    queryKey: [...PIPELINE_CATEGORIES_KEY, params.jobOpeningId],
    queryFn: () =>
      fetchPipelineCategories({ jobOpeningId: params.jobOpeningId }),
    enabled: options?.enabled ?? true,
  });
};

export const useUpdatePipelineCategoryMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updatePipelineCategory,
    onMutate: async ({ jobOpeningId, category: updatedCategory }: any) => {
      const queryKey = [...PIPELINE_CATEGORIES_KEY, jobOpeningId];
      // cancel queries to avoid overwriting optimistic update
      await queryClient.cancelQueries({
        queryKey,
      });

      // manually update state
      const previousCategories =
        queryClient.getQueryData<IPipelineCategory[]>(queryKey) ?? [];

      const previousCategoriesWithoutUpdated = previousCategories.filter(
        (category) => category.id !== updatedCategory.id
      );

      const updatedCategories = sortBy(
        [...previousCategoriesWithoutUpdated, updatedCategory],
        'orderWeight'
      );

      queryClient.setQueryData(queryKey, updatedCategories);

      // return previous state to be able to roll back in case of error
      return { previousCategories, queryKey };
    },
    onError: (_err, _variables: any, context: any) => {
      queryClient.setQueryData(context!.queryKey, context!.previousCategories!);
    },
    onSettled: (_data, _err, variables) => {
      queryClient.invalidateQueries({
        queryKey: [...PIPELINE_CATEGORIES_KEY, variables.jobOpeningId],
      });
    },
    ...(options as any),
  });
};

export const useCreatePipelineCategoryMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation<
    IPipelineCategory,
    Error,
    { jobOpeningId: TId; name: string; orderWeight: number }
  >({
    mutationFn: createPipelineCategory,
    onSuccess: (_data, { jobOpeningId }: any) => {
      queryClient.invalidateQueries({
        queryKey: [...PIPELINE_CATEGORIES_KEY, jobOpeningId],
      });
    },
    ...(options as any),
  });
};

export const useDeletePipelineCategoryMutation = (
  options?: UseMutationOptions
) => {
  const { addNotification } = useNotifications();
  const handleApiError = useApiError();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deletePipelineCategory,
    meta: { skipDefaultErrorHandler: true },
    onSuccess: (_data, { jobOpeningId }: any) => {
      queryClient.invalidateQueries({
        queryKey: [...PIPELINE_CATEGORIES_KEY, jobOpeningId],
      });
      queryClient.invalidateQueries({
        queryKey: [...JOB_OPENING_QUERY_KEY, jobOpeningId],
      });
      queryClient.invalidateQueries({
        queryKey: DASHBOARD_JOB_OPENINGS_QUERY_KEY,
      });
    },
    onError: (error) => {
      if (checkClientError(error, 'invalid')) {
        addNotification({ type: 'stage_with_candidates' });
      } else {
        handleApiError(error);
      }
    },
    ...(options as any),
  });
};
