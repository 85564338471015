import { useQuery } from '@tanstack/react-query';

import { fetchCandidateHistory } from '@/features/candidate';
import { TId } from '@/features/common';

export const CANDIDATE_HISTORY_QUERY_KEY = ['candidate', 'history'];

export const useCandidateHistoryQuery = (
  params: {
    candidateId: TId;
    limit: number;
    offset: number;
  },
  options?: { enabled?: boolean }
) =>
  useQuery({
    queryKey: [
      ...CANDIDATE_HISTORY_QUERY_KEY,
      params.candidateId,
      params.limit,
      params.offset,
    ],
    queryFn: () => fetchCandidateHistory(params),
    enabled: options?.enabled ?? true,
  });
