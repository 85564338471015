import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';
import { sortBy } from 'lodash';
import { usePostHog } from 'posthog-js/react';

import { getAmplitudeClient } from '@/features/amplitude/utils/amplitude';
import { Icon, SemanticButton } from '@/features/common';
import { useDashboardJobOpeningsQuery } from '@/features/dashboard';
import { usePreferenceMutation, usePreferencesQuery } from '@/features/me';
import {
  TChecklistItem,
  useWorkspaceQuery,
  useWorkspaceStatsQuery,
  WORKSPACE_STATS_QUERY_KEY,
} from '@/features/workspace';
import { isPaidPlan } from '@/hooks/planLimits';
import { useHistory, useWorkspaceId } from '@/hooks/router';
import { queryClient } from '@/utils/reactQuery';

import { useUpdateChecklistMutation } from '../../queries';

import { ChecklistItem, CheersIllustration, ProgressBar } from './ui';

export function Checklist() {
  const amplitude = getAmplitudeClient();
  const posthog = usePostHog();
  const history = useHistory();
  const workspaceId = useWorkspaceId();

  const workspaceStatsQuery = useWorkspaceStatsQuery({ workspaceId });
  const updateChecklistMutation = useUpdateChecklistMutation();
  const workspaceQuery = useWorkspaceQuery({ workspaceId });
  const preferencesQuery = usePreferencesQuery();
  const preferenceMutation = usePreferenceMutation();
  const dashboardJobOpeningsQuery = useDashboardJobOpeningsQuery({
    workspaceId,
    filter: 'open',
  });

  const [progress, setProgress] = useState<number>(25);
  const [isChecklistClosedForSession, setIsChecklistClosedForSession] =
    useState(false);

  const isChecklistClosedForGood = preferencesQuery.isSuccess
    ? preferencesQuery.data?.closedActivityFeedChecklist
    : false;

  const firstJobOpening = dashboardJobOpeningsQuery.isSuccess
    ? sortBy(dashboardJobOpeningsQuery.data.openings, 'id')[0]
    : undefined;

  const checkListItems = useMemo(() => {
    const findCheckListItem = (checkListItem: TChecklistItem) => {
      if (workspaceStatsQuery.isSuccess) {
        return (
          !!workspaceStatsQuery.data.checklist?.find(
            (item) => item === checkListItem
          ) || false
        );
      }
      return false;
    };
    return [
      {
        name: 'Create your first job',
        done: findCheckListItem('CreateFirstJob'),
        clickable: !findCheckListItem('CreateFirstJob'),
        onClick: () => {
          if (!findCheckListItem('CreateFirstJob')) {
            history.push(`/admin/${workspaceId}/test-library`);
            amplitude?.logEvent('dashboard/checklist', {
              type: 'CreateFirstJob',
            });
            posthog?.capture('hire_admin_app:dashboard/checklist', {
              type: 'CreateFirstJob',
            });
          }
        },
      },
      {
        name: 'Update job description',
        done: findCheckListItem('EditJobDescription'),
        clickable:
          !findCheckListItem('EditJobDescription') && !!firstJobOpening,
        onClick: () => {
          if (!findCheckListItem('EditJobDescription') && !!firstJobOpening) {
            history.push(
              `/admin/${workspaceId}/openings/${firstJobOpening?.id}/job-description`
            );
            amplitude?.logEvent('dashboard/checklist', {
              type: 'EditJobDescription',
            });
            posthog?.capture('hire_admin_app:dashboard/checklist', {
              type: 'EditJobDescription',
            });
          }
        },
      },
      {
        name: 'Add a test',
        done: findCheckListItem('CreateTest'),
        clickable: !findCheckListItem('CreateTest') && !!firstJobOpening,
        onClick: () => {
          if (!findCheckListItem('CreateTest') && !!firstJobOpening) {
            history.push(`/admin/${workspaceId}/test-library`);
            amplitude?.logEvent('dashboard/checklist', { type: 'CreateTest' });
            posthog?.capture('hire_admin_app:dashboard/checklist', {
              type: 'CreateTest',
            });
          }
        },
      },
      {
        name: 'Preview & share',
        done: findCheckListItem('PreviewJob'),
        clickable: !findCheckListItem('PreviewJob') && !!firstJobOpening,
        onClick: () => {
          if (!findCheckListItem('PreviewJob') && !!firstJobOpening) {
            window.open(`/c/${firstJobOpening?.slug}/overview`, '_blank');
            updateChecklistMutation.mutate(
              {
                workspaceId,
                checklistItem: 'PreviewJob',
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: WORKSPACE_STATS_QUERY_KEY,
                  });
                },
              }
            );
            amplitude?.logEvent('dashboard/checklist', { type: 'PreviewJob' });
            posthog?.capture('hire_admin_app:dashboard/checklist', {
              type: 'PreviewJob',
            });
          }
        },
      },
    ];
  }, [
    firstJobOpening,
    workspaceStatsQuery.isSuccess,
    workspaceStatsQuery.data,
    history,
    workspaceId,
    amplitude,
    updateChecklistMutation,
    posthog,
  ]);

  const isChecklistFinished = checkListItems.every((item) => item.done);

  const onCloseClick = () => {
    if (isChecklistFinished) {
      preferenceMutation.mutate({
        name: 'closedActivityFeedChecklist',
        value: true,
      });
    } else {
      setIsChecklistClosedForSession(true);
    }
  };

  useEffect(() => {
    setProgress(
      (checkListItems.filter((item) => item.done).length * 100) /
        checkListItems.length
    );
  }, [checkListItems]);

  if (
    !workspaceQuery.data ||
    workspaceQuery.data.createdAt.toISOString() <= '2024-09-23T11:40:14.210Z' ||
    ((isChecklistClosedForGood || isChecklistClosedForSession) &&
      isPaidPlan(workspaceQuery.data.subscriptionPlan))
  ) {
    return null;
  }

  return (
    <Container>
      {!isChecklistClosedForSession && !isChecklistClosedForGood && (
        <ChecklistContent>
          <ChecklistContentHeader>
            <h2>
              {isChecklistFinished
                ? `All done! Ready to get the most of Toggl Hire?`
                : `Let’s set up your job`}
            </h2>
            <CloseButton onClick={onCloseClick}>
              <Icon icon={regular('xmark')} color="white" fontSize="12px" />
            </CloseButton>
          </ChecklistContentHeader>

          {isChecklistFinished ? (
            <IllustrationContainer>
              <CheersIllustration />
            </IllustrationContainer>
          ) : (
            <ChecklistProgress>
              <ProgressBar value={progress} />
              {checkListItems.map((item) => (
                <ChecklistItem
                  key={item.name}
                  name={item.name}
                  done={item.done}
                  clickable={item.clickable && !item.done}
                  onClick={item.onClick}
                />
              ))}
            </ChecklistProgress>
          )}
        </ChecklistContent>
      )}

      <DemoContent
        secondary={
          !isChecklistFinished &&
          !isChecklistClosedForSession &&
          !isChecklistClosedForGood
        }
      >
        <p>Want to chat to a product expert?</p>
        <DSButton
          size="xsmall"
          onClick={() => {
            amplitude?.logEvent(
              'dashboard/checklist-training-session-button-click'
            );
            posthog?.capture(
              'hire_admin_app:dashboard/checklist-training-session-button-click'
            );
            window.open(
              'https://toggl.com/hire/demo?utm_source=product&utm_medium=button&utm_campaign=onboarding+checklist',
              '_blank'
            );
          }}
          variant="secondary"
          fullWidth
        >
          Book a demo
        </DSButton>
      </DemoContent>
    </Container>
  );
}

const Container = styled.section`
  margin: auto 8px 0;
`;

const ChecklistContent = styled.div`
  background: ${({ theme }) => theme.colors.purple[100]};
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.purple[90]};
  border-bottom: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
`;

const ChecklistContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;

  h2 {
    margin: 4px 0 0 0;
    color: ${({ theme }) => theme.colors.white};
    font-size: 12px;
    font-weight: 700;
  }
`;

const CloseButton = styled(SemanticButton)`
  display: flex;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
  }
`;

const ChecklistProgress = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 4px 0;
`;

const IllustrationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.purple[90]};
  border-radius: 8px;
  height: 86px;
`;

const DemoContent = styled.div<{ secondary: boolean }>`
  display: flex;
  padding: 12px 8px;
  flex-direction: column;
  gap: 12px;
  background: ${({ secondary, theme }) =>
    secondary ? theme.colors.purple[90] : theme.colors.purple[100]};
  border: 1px solid ${({ theme }) => theme.colors.purple[90]};
  border-top: none;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  :first-child {
    border-top: 1px solid ${({ theme }) => theme.colors.purple[90]};
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  p {
    margin: 0;
    color: ${({ theme }) => theme.colors.white};
    font-size: 10px;
    font-weight: 500;
  }
`;
