import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { TId } from '@/features/common';

import { postFeedbackChurnSurvey } from '../api';

export const usePostFeedbackChurnSurvey = (options?: UseMutationOptions) =>
  useMutation<
    void,
    Error,
    { workspaceId: TId; reasons: string[]; feedback: string }
  >({ mutationFn: postFeedbackChurnSurvey, ...(options as any) });
