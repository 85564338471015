import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { TId } from '@/features/common';
import { ME_QUERY_KEY } from '@/features/me';

import { finishOnboarding } from '../api';

export const useFinishOnboardingMutation = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, { workspaceId: TId }>({
    mutationFn: finishOnboarding,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [...ME_QUERY_KEY] });
    },
    ...(options as any),
  });
};
