import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import {
  CANDIDATE_HISTORY_QUERY_KEY,
  CANDIDATE_QUERY_KEY,
  deleteGmailEmail,
  fetchCandidateEmails,
  ICandidateEmailsResult,
  sendEmail,
  sendGmailEmailReply,
  syncGmailEmails,
} from '@/features/candidate';
import { TId } from '@/features/common';
import { sendBulkGmailEmail } from '@/features/email/api/emails';
import { useNotifications } from '@/features/notifications';

export const CANDIDATE_EMAILS_QUERY_KEY = ['candidate', 'emails'];

export const useCandidateEmailQuery = (params: {
  candidateId: TId;
  limit: number;
  offset: number;
}) =>
  useQuery<ICandidateEmailsResult>({
    queryKey: [
      ...CANDIDATE_EMAILS_QUERY_KEY,
      params.candidateId,
      params.limit,
      params.offset,
    ],
    queryFn: () => fetchCandidateEmails(params),
  });

export const useSyncGmailEmailsMutation = (
  params: { candidateId: TId },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const { addNotification } = useNotifications();

  return useMutation({
    mutationFn: () => syncGmailEmails({ candidateId: params.candidateId }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [...CANDIDATE_EMAILS_QUERY_KEY, params.candidateId],
      });

      addNotification({ type: 'gmail_synced' });
    },
    ...(options as any),
  });
};

export const useSendEmailMutation = (
  params: { candidateId: TId },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation<
    void,
    Error,
    {
      candidateId: TId;
      subject: string;
      message: string;
      sendAt?: Date;
      cc?: string[];
      bcc?: string[];
    }
  >({
    mutationFn: sendEmail,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [...CANDIDATE_QUERY_KEY, params.candidateId],
      });
      queryClient.invalidateQueries({
        queryKey: [...CANDIDATE_HISTORY_QUERY_KEY, params.candidateId],
      });
      queryClient.invalidateQueries({
        queryKey: [...CANDIDATE_EMAILS_QUERY_KEY, params.candidateId],
      });
    },
    ...(options as any),
  });
};

export const useSendBulkEmailMutation = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: sendBulkGmailEmail,
    onSuccess: (_, variables: any) => {
      variables.candidateIds.forEach((candidateId) => {
        queryClient.invalidateQueries({
          queryKey: [...CANDIDATE_EMAILS_QUERY_KEY, candidateId],
        });
      });
    },
    ...(options as any),
  });
};

export const useSendGmailEmailReplyMutation = (
  params: { candidateId: TId },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation<
    void,
    Error,
    {
      candidateId: TId;
      emailId: string;
      subject: string;
      message: string;
      cc?: string[];
      bcc?: string[];
      sendAt?: Date;
    }
  >({
    mutationFn: sendGmailEmailReply,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [...CANDIDATE_EMAILS_QUERY_KEY, params.candidateId],
      });
    },
    ...(options as any),
  });
};

export const useDeleteGmailEmailMutation = (
  params: { candidateId: TId },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const { addNotification } = useNotifications();

  return useMutation<void, Error, { candidateId: TId; emailId: string }>({
    mutationFn: deleteGmailEmail,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [...CANDIDATE_EMAILS_QUERY_KEY, params.candidateId],
      });

      addNotification({ type: 'gmail_email_deleted' });
    },
    ...(options as any),
  });
};
