import {
  useMutation,
  UseMutationOptions,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import {
  CANDIDATE_EMAILS_QUERY_KEY,
  CANDIDATE_HISTORY_QUERY_KEY,
  CANDIDATE_QUERY_KEY,
} from '@/features/candidate';
import { TId } from '@/features/common';
import { DASHBOARD_JOB_OPENINGS_QUERY_KEY } from '@/features/dashboard';
import { JOB_OPENING_QUERY_KEY } from '@/features/job-opening';
import {
  IPipelineCategory,
  PIPELINE_CANDIDATES_KEY,
  PIPELINE_CATEGORIES_KEY,
} from '@/features/pipeline';
import {
  createTest,
  deleteTest,
  fetchTest,
  inviteCandidateToTest,
  ITest,
  updateTest,
} from '@/features/test';

export const TEST_QUERY_KEY = ['test'];

export const useTestQuery = (
  params: { testId?: TId },
  options?: {
    enabled?: boolean;
    meta?: Record<string, unknown>;
    retry?: boolean;
  }
) => {
  return useQuery<ITest>({
    queryKey: [...TEST_QUERY_KEY, params.testId],
    queryFn: () => fetchTest(params as { testId: string }),
    enabled: !!params.testId && (options?.enabled ?? true),
    meta: {
      ...options?.meta,
      skipDefaultErrorHandler: options?.meta?.skipDefaultErrorHandler ?? false,
    },
    retry: options?.retry ?? false,
  });
};

export const useTestsQuery = (
  params: { testIds?: TId[] },
  options?: { enabled?: boolean }
) => {
  return useQueries({
    queries: (params.testIds || []).map<UseQueryOptions<ITest>>((testId) => {
      return {
        queryKey: [...TEST_QUERY_KEY, testId],
        queryFn: () => fetchTest({ testId }),
        enabled: options?.enabled ?? true,
      };
    }),
  });
};

export const useUpdateTestMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateTest,
    async onMutate({ testId, test }) {
      await queryClient.cancelQueries({
        queryKey: [...TEST_QUERY_KEY, testId],
      });
      const previousTest = queryClient.getQueryData([
        ...TEST_QUERY_KEY,
        testId,
      ]);
      queryClient.setQueryData<Partial<ITest> | undefined>(
        [...TEST_QUERY_KEY, testId],
        (oldTest) => ({
          ...oldTest,
          ...test,
        })
      );

      return { previousTest };
    },
    async onError(_, variables, context) {
      queryClient.setQueryData(
        [...TEST_QUERY_KEY, variables.testId],
        context?.previousTest
      );
      await queryClient.invalidateQueries({
        queryKey: [...TEST_QUERY_KEY, variables.testId],
      });
    },
    async onSuccess(_, variables: { testId: TId; test: Partial<ITest> }) {
      if (variables.test.jobOpeningId) {
        //Base query key for all pipeline candidates
        let queryKey = [
          ...PIPELINE_CANDIDATES_KEY,
          variables.test.jobOpeningId,
        ];
        const categories = queryClient.getQueryData<IPipelineCategory[]>([
          ...PIPELINE_CATEGORIES_KEY,
          variables.test.jobOpeningId,
        ]);
        const testCategory = categories?.find(
          (c) => c.testId === variables.testId
        );

        // if we know the test category, we can invalidate only this category
        if (testCategory) {
          queryKey.push(testCategory.id);
        }

        await queryClient.invalidateQueries({ queryKey });
      }
    },
  });
};

export const useCreateTestMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createTest,
    async onSuccess(test) {
      await Promise.allSettled([
        queryClient.invalidateQueries({
          queryKey: [...PIPELINE_CATEGORIES_KEY, test.jobOpeningId],
        }),
        queryClient.invalidateQueries({
          queryKey: [...JOB_OPENING_QUERY_KEY, test.jobOpeningId],
        }),
        queryClient.invalidateQueries({
          queryKey: DASHBOARD_JOB_OPENINGS_QUERY_KEY,
        }),
      ]);
    },
  });
};

export const useDeleteTestMutation = ({
  jobOpeningId,
}: {
  jobOpeningId: TId;
}) => {
  const queryClient = useQueryClient();

  return useMutation<void, unknown, { testId: TId }>({
    mutationFn: ({ testId }: { testId: TId }) => deleteTest(testId),
    onSuccess: async () => {
      await Promise.allSettled([
        queryClient.invalidateQueries({
          queryKey: [...PIPELINE_CATEGORIES_KEY, jobOpeningId],
        }),
        queryClient.invalidateQueries({ queryKey: CANDIDATE_QUERY_KEY }),
        queryClient.invalidateQueries({
          queryKey: [...JOB_OPENING_QUERY_KEY, jobOpeningId],
        }),
        queryClient.invalidateQueries({
          queryKey: DASHBOARD_JOB_OPENINGS_QUERY_KEY,
        }),
      ]);
    },
  });
};

export const useInviteCandidateToTestMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: inviteCandidateToTest,
    onSuccess: (_, variables: any) => {
      queryClient.invalidateQueries({
        queryKey: [...CANDIDATE_QUERY_KEY, variables.candidateId],
      });
      queryClient.invalidateQueries({
        queryKey: [...CANDIDATE_HISTORY_QUERY_KEY, variables.candidateId],
      });
      queryClient.invalidateQueries({
        queryKey: [...PIPELINE_CANDIDATES_KEY, variables.candidateId],
      });
      queryClient.invalidateQueries({
        queryKey: [...CANDIDATE_EMAILS_QUERY_KEY, variables.candidateId],
      });
    },
    ...(options as any),
  });
};
