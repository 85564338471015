import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import { TId } from '@/features/common';
import {
  createVideoIntroduction,
  deleteVideoIntroduction,
  TEST_QUERY_KEY,
} from '@/features/test';
import { Test, TestStats } from '@/types/h5test';

const useInvalidateTestQuery = () => {
  const queryClient = useQueryClient();
  return async (test: Test) => {
    await queryClient.invalidateQueries({
      queryKey: [...TEST_QUERY_KEY, test.id],
    });
  };
};

export const useCreateVideoIntroductionMutation = ({
  onSuccess,
  ...options
}: UseMutationOptions) => {
  const invalidateTestQuery = useInvalidateTestQuery();
  return useMutation<
    {
      test: Test;
      testStats: TestStats;
    },
    Error,
    { testId: TId; video: Blob }
  >({
    mutationFn: createVideoIntroduction,
    onSuccess: async (data: any) => {
      await invalidateTestQuery(data.test);
    },
    ...(options as any),
  });
};

export const useDeleteVideoIntroductionMutation = ({
  onSuccess,
  ...options
}: UseMutationOptions) => {
  const invalidateTestQuery = useInvalidateTestQuery();
  return useMutation<
    {
      test: Test;
      testStats: TestStats;
    },
    Error,
    { testId: TId }
  >({
    mutationFn: deleteVideoIntroduction,
    onSuccess: async (data: any) => {
      await invalidateTestQuery(data.test);
    },
    ...(options as any),
  });
};
