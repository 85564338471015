import * as React from 'react';

import { retryableLazyImport } from '@/features/app/components/lazy-loader';

export const TeamSettingsPage = React.lazy(() =>
  retryableLazyImport(() => import('@/pages/workspace-team-settings-page'))
);

export const WorkspaceSettingsIntegration = React.lazy(() =>
  retryableLazyImport(
    () => import('@/pages/workspace/workspace-settings-integrations-page')
  )
);

export const BillingPage = React.lazy(() =>
  retryableLazyImport(() => import('@/pages/settings/billing-page'))
);

export const DashboardPage = React.lazy(() =>
  retryableLazyImport(() => import('@/pages/dashboard-page/dashboard-page'))
);

export const JobOpeningPage = React.lazy(() =>
  retryableLazyImport(() => import('@/pages/openings/job-opening-page'))
);

export const JobOpeningAnalyticsPage = React.lazy(() =>
  retryableLazyImport(() => import('@/pages/job-opening-analytics-page'))
);

export const CandidateSatisfactionPage = React.lazy(() =>
  retryableLazyImport(() => import('@/pages/candidate-satisfaction-page'))
);

export const PipelinePage = React.lazy(() =>
  retryableLazyImport(() => import('@/pages/pipeline/pipelines-page'))
);

export const PipelineRecommendedSingleStagePage = React.lazy(() =>
  retryableLazyImport(
    () => import('@/pages/pipeline/pipeline-recommended-single-stage-page')
  )
);

export const PipelineSingleStagePage = React.lazy(() =>
  retryableLazyImport(
    () => import('@/pages/pipeline/pipeline-single-stage-page')
  )
);

export const JobOpeningSettingsPage = React.lazy(() =>
  retryableLazyImport(() => import('@/pages/job-opening-settings-page'))
);

export const SettingsEmailTemplatePage = React.lazy(() =>
  retryableLazyImport(() => import('@/pages/settings/email-template-page'))
);
export const WorkspaceSettingsSSOPage = React.lazy(() =>
  retryableLazyImport(
    () => import('@/pages/workspace/workspace-settings-sso-page')
  )
);

export const InboxPage = React.lazy(() =>
  retryableLazyImport(() => import('@/pages/inbox-page'))
);
export const AnalyticsPage = React.lazy(() =>
  retryableLazyImport(() => import('@/pages/analytics-page'))
);

export const TestLibraryDetailsPage = React.lazy(() =>
  retryableLazyImport(() => import('@/pages/test-library-details-page'))
);

export const JobOpeningApplicationFormPage = React.lazy(() =>
  retryableLazyImport(() => import('@/pages/job-opening-application-form-page'))
);
