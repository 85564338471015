import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  fetchJobOpeningEmailNotifications,
  JobOpeningEmailNotifications,
  updateJobOpeningEmailNotifications,
} from '@/features/job-opening';
import { useJobOpeningId } from '@/hooks/router';

const JOB_OPENING_EMAIL_NOTIFICATIONS_KEY = ['job-opening-email-notifications'];

export const useJobOpeningEmailNotificationsQuery = (options?: {
  enabled?: boolean;
}) => {
  const jobOpeningId = useJobOpeningId();
  return useQuery({
    queryKey: [...JOB_OPENING_EMAIL_NOTIFICATIONS_KEY, jobOpeningId],
    queryFn: () => fetchJobOpeningEmailNotifications({ jobOpeningId }),
    enabled: options?.enabled ?? true,
  });
};

export const useUpdateJobOpeningEmailNotificationsMutation = () => {
  const jobOpeningId = useJobOpeningId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (settings: JobOpeningEmailNotifications) =>
      updateJobOpeningEmailNotifications({ jobOpeningId, settings }),
    mutationKey: JOB_OPENING_EMAIL_NOTIFICATIONS_KEY,

    onSuccess(data) {
      const queryKey = [...JOB_OPENING_EMAIL_NOTIFICATIONS_KEY, jobOpeningId];
      queryClient.setQueryData(queryKey, data);
    },
  });
};
