import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { BillingPlan } from '@/features/billing';
import { TId } from '@/features/common';
import { ME_QUERY_KEY } from '@/features/me';
import { fetchMe } from '@/features/me/api';
import {
  createWorkspace,
  deleteWorkspace,
  IWorkspace,
  updateWorkspace,
  startWorkspaceFreeTrial,
  finishWorkspaceFreeTrial,
} from '@/features/workspace';

export const useWorkspaceQuery = ({ workspaceId }: { workspaceId?: TId }) =>
  useQuery({
    queryKey: ME_QUERY_KEY,
    queryFn: fetchMe,
    enabled: Boolean(workspaceId),
    select: (data) =>
      data.workspaces?.find((workspace) => workspace.id === workspaceId),
  });

interface IMutationParams {
  onSuccess?: () => void;
}

export const useCreateWorkspaceMutation = ({
  onSuccess,
}: {
  onSuccess: (workspace: IWorkspace) => void;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createWorkspace,
    onSuccess: (workspace) => {
      queryClient.invalidateQueries({ queryKey: ME_QUERY_KEY });
      onSuccess?.(workspace);
    },
  });
};

export const useUpdateWorkspaceMutation = ({ onSuccess }: IMutationParams) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateWorkspace,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ME_QUERY_KEY });
      onSuccess?.();
    },
  });
};

export const useStartWorkspaceFreeTrialMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation<
    void,
    Error,
    { workspaceId: TId; subscribePlan?: BillingPlan }
  >({
    mutationFn: startWorkspaceFreeTrial,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ME_QUERY_KEY });
    },
    ...(options as any),
  });
};

export const useFinishWorkspaceFreeTrialMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, { workspaceId: TId; trialId: string }>({
    mutationFn: finishWorkspaceFreeTrial,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ME_QUERY_KEY });
    },
    ...(options as any),
  });
};

export const useDeleteWorkspaceMutation = ({ onSuccess }: IMutationParams) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteWorkspace,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ME_QUERY_KEY });
      onSuccess?.();
    },
  });
};
