import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { TId } from '@/features/common';
import { useWorkspaceId } from '@/hooks/router';

import {
  createWorkspaceWebhook,
  deleteWorkspaceWebhook,
  fetchWorkspaceWebhooks,
  updateWorkspaceWebhook,
} from '../api';

const WORKSPACE_WEBHOOKS_KEY = ['workspace', 'webhooks'];

export const useWorkspaceWebhooksQuery = () => {
  const workspaceId = useWorkspaceId();
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(WORKSPACE_WEBHOOKS_KEY, {
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [...WORKSPACE_WEBHOOKS_KEY, workspaceId],
      }),
  });

  return useQuery({
    queryKey: [...WORKSPACE_WEBHOOKS_KEY, workspaceId],
    queryFn: () => fetchWorkspaceWebhooks({ workspaceId }),
  });
};

export const useCreateWorkspaceWebhookMutation = (
  options?: UseMutationOptions
) => {
  return useMutation<
    void,
    Error,
    {
      workspaceId: TId;
      attributes: {
        name: string;
        url: string;
        auth_user_name: string;
        auth_password: string;
      };
    }
  >({
    mutationFn: createWorkspaceWebhook,
    mutationKey: WORKSPACE_WEBHOOKS_KEY,
    ...(options as any),
  });
};

export const useUpdateWebhookMutation = (options?: UseMutationOptions) => {
  return useMutation<
    void,
    Error,
    {
      webhookId: string;
      attributes: {
        name: string;
        url: string;
        auth_user_name: string;
        auth_password: string;
      };
    }
  >({
    mutationFn: updateWorkspaceWebhook,
    mutationKey: WORKSPACE_WEBHOOKS_KEY,
    ...(options as any),
  });
};

export const useDeleteWebhookMutation = (options?: UseMutationOptions) => {
  return useMutation<void, Error, { webhookId: TId }>({
    mutationFn: deleteWorkspaceWebhook,
    mutationKey: WORKSPACE_WEBHOOKS_KEY,
    ...(options as any),
  });
};
