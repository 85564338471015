import { useMutation, useQuery } from '@tanstack/react-query';

import { fetchWorkspaceStats, markStatsAsSeen } from '@/features/workspace';
import { Id } from '@/types/misc';

export const WORKSPACE_STATS_QUERY_KEY = ['workspaceStats'];

export const useWorkspaceStatsQuery = ({
  workspaceId,
}: {
  workspaceId?: Id;
}) => {
  return useQuery({
    queryKey: [...WORKSPACE_STATS_QUERY_KEY, workspaceId],
    queryFn: () => fetchWorkspaceStats(workspaceId!),

    enabled: !!workspaceId,
  });
};

export const WORKSPACE_CANDIDATES_STATS_QUERY_KEY = [
  'workspaceCandidatesStats',
];

export const useMarkStatsAsSeenMutation = ({
  workspaceId,
}: {
  workspaceId: Id;
}) => {
  return useMutation({ mutationFn: () => markStatsAsSeen({ workspaceId }) });
};
