import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSContextMenu, DSContextMenuButton } from '@hundred5/design-system';
import { useHistory } from 'react-router';

import { copyText, Icon, TId } from '@/features/common';
import { useJobOpeningQuery } from '@/features/job-opening';
import { getJobOpeningUrlForCandidate } from '@/features/job-opening/utils/job-opening-url';
import { useNotifications } from '@/features/notifications';
import { useJobOpeningPermission } from '@/features/permissions';
import { useJobOpeningId, useWorkspaceId } from '@/hooks/router';

import { useTestQuery } from '../../queries';
import { DeleteTestModal } from '../delete-test-modal';

interface ContextMenuProps {
  testId: TId;
}

export function ContextMenu({ testId }: ContextMenuProps) {
  const { addNotification } = useNotifications();
  const history = useHistory();
  const jobOpeningId = useJobOpeningId();
  const workspaceId = useWorkspaceId();

  const [menuOpen, setMenuOpen] = React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

  const jobOpeningQuery = useJobOpeningQuery();
  const testQuery = useTestQuery({ testId });

  const canEditPipeline = !!useJobOpeningPermission()('pipeline', 'update');

  const handleCopyTestLink = () => {
    copyText(
      getJobOpeningUrlForCandidate({
        jobOpeningSlug: jobOpeningQuery.data?.slug!,
        hashedTestId: testQuery.data?.hashedTestId!,
      })
    );
    addNotification({ type: 'copied_to_clipboard' });
  };

  if (!testQuery.isSuccess || !jobOpeningQuery.isSuccess) {
    return null;
  }

  return (
    <ContextMenuContainer>
      <DSContextMenuButton
        active={menuOpen}
        onClick={() => setMenuOpen(!menuOpen)}
      />
      <DSContextMenu open={menuOpen} position={{ top: '32px', right: '0' }}>
        <div>
          <DSContextMenu.Item
            label="Copy test link"
            icon={<Icon icon={regular('link-simple')} color="purple-60" />}
            onClick={handleCopyTestLink}
          />
        </div>
        {canEditPipeline ? (
          <>
            <DSContextMenu.Delimiter />
            <DSContextMenu.Item
              label="Delete test"
              icon={<Icon icon={regular('trash')} color="purple-60" />}
              onClick={() => setDeleteModalOpen(true)}
            />
          </>
        ) : null}
      </DSContextMenu>

      <React.Suspense fallback={null}>
        {deleteModalOpen && (
          <DeleteTestModal
            testId={testId}
            open={deleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            onSuccess={() => {
              setDeleteModalOpen(false);
              history.push(
                `/admin/${workspaceId}/openings/${jobOpeningId}/pipeline`
              );
            }}
          />
        )}
      </React.Suspense>
    </ContextMenuContainer>
  );
}

const ContextMenuContainer = styled.div`
  position: relative;
  margin-left: 8px;
`;
