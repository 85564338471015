import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { useMeQuery } from '@/features/me';
import { useWorkspaceId } from '@/hooks/router';

import {
  fetchInboxItems,
  fetchInboxTotalUnread,
  markInboxItemsSeen,
} from '../api';
import { useInboxSearchParams } from '../hooks/params';

export const INBOX_KEY = ['inbox'];

export const useInboxItemsQuery = () => {
  const workspaceId = useWorkspaceId();
  const { searchParams } = useInboxSearchParams();
  const me = useMeQuery();

  return useQuery({
    queryKey: [...INBOX_KEY, searchParams, workspaceId],
    queryFn: () => fetchInboxItems(workspaceId, searchParams),

    enabled:
      !!(searchParams && parseInt(searchParams.page)) && me.data?.id !== '0', // avoid making the request in support mode
  });
};

export const useInboxTotalUnreadQuery = () => {
  const workspaceId = useWorkspaceId();
  const me = useMeQuery();

  return useQuery({
    queryKey: [...INBOX_KEY, 'totalUnread', workspaceId],
    queryFn: () => fetchInboxTotalUnread(workspaceId),
    enabled: me.data?.id !== '0', // avoid making the request in support mode
  });
};

export const useMarkInboxItemsSeenMutation = (options?: UseMutationOptions) => {
  const workspaceId = useWorkspaceId();
  const queryClient = useQueryClient();
  const { searchParams } = useInboxSearchParams();

  return useMutation({
    mutationFn: markInboxItemsSeen,
    onSuccess: (_, variables: any) => {
      if (!!variables?.itemsIds?.length) {
        queryClient.invalidateQueries({
          queryKey: [...INBOX_KEY, searchParams, workspaceId],
        });
      }
      queryClient.invalidateQueries({
        queryKey: [...INBOX_KEY, 'totalUnread', workspaceId],
      });
    },
    ...(options as any),
  });
};
