import { useCallback } from 'react';
import {
  Query,
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { sortBy } from 'lodash';

import { bulkUpdate } from '@/features/bulk-actions/api';
import {
  CANDIDATE_QUERY_KEY,
  ICandidate,
  updateCandidate,
} from '@/features/candidate';
import { ICandidateSearchParams } from '@/features/candidate-search';
import { TId } from '@/features/common';

import { fetchPipelineCategoryCandidates } from '../api/pipeline-candidates';
import { IPipelineCandidate, TPipelineCandidatesSortOrder } from '../types';

export const PIPELINE_CANDIDATES_KEY = ['pipeline-candidates'];

export const usePipelineCategoryCandidatesQuery = (
  params: {
    jobOpeningId: TId;
    categoryId: TId;
    searchParams: ICandidateSearchParams;
  },
  options?: { enabled?: boolean }
) => {
  return useInfiniteQuery({
    initialPageParam: { offset: 0 },
    queryKey: [
      ...PIPELINE_CANDIDATES_KEY,
      params.jobOpeningId,
      params.categoryId,
      {
        ...params.searchParams,
      },
    ],
    queryFn: ({ pageParam }: any) =>
      fetchPipelineCategoryCandidates({
        jobOpeningId: params.jobOpeningId,
        categoryId: params.categoryId,
        pageParam,
        searchParams: params.searchParams,
      }),
    getNextPageParam: (lastPage: any, pages) => {
      const total = lastPage.total;
      const current = pages.reduce(
        (sum, page) => sum + page.candidates?.length || 0,
        0
      );

      if (current < total) {
        return {
          offset: current,
        };
      }

      return undefined;
    },
    enabled: options?.enabled ?? true,
  });
};

export const useDragCandidateMutation = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      candidate,
      attributes,
    }: {
      jobOpeningId: TId;
      candidate: ICandidate;
      attributes: Partial<ICandidate>;
    }) => {
      return updateCandidate({ candidateId: candidate.id, attributes });
    },
    onMutate: async ({ jobOpeningId, candidate }: any) => {
      // optimistic removal
      const previousCategoryQueries = queryClient.getQueryCache().findAll({
        queryKey: [
          ...PIPELINE_CANDIDATES_KEY,
          jobOpeningId,
          candidate.categoryId,
        ],
      }) as Query<{
        pages: { total: number; candidates: ICandidate[] }[];
      }>[];

      const lastQuery = sortBy(
        previousCategoryQueries,
        'state.dataUpdatedAt'
      ).pop();

      if (!lastQuery) {
        return;
      }

      queryClient.setQueryData(lastQuery.queryKey, {
        ...lastQuery.state.data,
        pages: lastQuery.state.data?.pages.map((page) => ({
          ...page,
          candidates: page.candidates.filter(
            (pageCandidate) => pageCandidate.id !== candidate.id
          ),
        })),
      });

      // TODO: optimistic update
      // insert candidate into target category (where? does it make sense given we'll invalidate the query in a sec anyway?)
    },
    onSettled: (_data, _err, variables: any) => {
      // invalidate all old category queries
      queryClient.invalidateQueries({
        queryKey: [
          ...PIPELINE_CANDIDATES_KEY,
          variables.jobOpeningId,
          variables.candidate.categoryId,
        ],
      });

      // invalidate all target category queries
      queryClient.invalidateQueries({
        queryKey: [
          ...PIPELINE_CANDIDATES_KEY,
          variables.jobOpeningId,
          variables.attributes.categoryId,
        ],
      });

      // invalidate single candidate query
      queryClient.invalidateQueries({
        queryKey: [...CANDIDATE_QUERY_KEY, variables.candidate.id],
      });
    },
    ...(options as any),
  });
};

export const useDragMultipleCandidatesMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      candidates,
      targetCategoryId,
    }: {
      jobOpeningId: TId;
      candidates: IPipelineCandidate[];
      targetCategoryId: TId;
    }) => {
      return bulkUpdate(
        candidates.map((candidate) => candidate.id),
        { categoryId: targetCategoryId }
      );
    },
    onMutate: async ({ jobOpeningId, candidates }: any) => {
      // optimistic removal
      const allQueries = queryClient.getQueryCache().findAll({
        queryKey: [...PIPELINE_CANDIDATES_KEY, jobOpeningId],
      }) as Query<{
        pages: { total: number; candidates: ICandidate[] }[];
      }>[];

      const idsToRemove = candidates.map((candidate) => candidate.id);

      allQueries.forEach((query) => {
        queryClient.setQueryData(query.queryKey, {
          ...query.state.data,
          pages: query.state.data?.pages.map((page) => ({
            ...page,
            candidates: page.candidates.filter(
              (pageCandidate) => !idsToRemove.includes(pageCandidate.id)
            ),
          })),
        });
      });

      // TODO: optimistic update
    },
    onSettled: (_data, _err, variables: any) => {
      const sourceCategoriesIds = [
        ...new Set(
          variables.candidates.map((candidate) => candidate.categoryId)
        ),
      ];

      // invalidate affected categories' queries
      sourceCategoriesIds.forEach((categoryId) => {
        queryClient.invalidateQueries({
          queryKey: [
            ...PIPELINE_CANDIDATES_KEY,
            variables.jobOpeningId,
            categoryId,
          ],
        });
      });

      // invalidate single candidate queries
      variables.candidates.forEach((candidate) => {
        queryClient.invalidateQueries({
          queryKey: [...CANDIDATE_QUERY_KEY, candidate.id],
        });
      });

      // invalidate all target category queries
      queryClient.invalidateQueries({
        queryKey: [
          ...PIPELINE_CANDIDATES_KEY,
          variables.jobOpeningId,
          variables.targetCategoryId,
        ],
      });
    },
    ...(options as any),
  });
};

export const useInvalidateCandidatesQuery = () => {
  const queryClient = useQueryClient();
  return useCallback(
    (params: {
      jobOpeningId: TId;
      categoryId: TId;
      searchParams: ICandidateSearchParams;
      sortOrder?: TPipelineCandidatesSortOrder;
    }) => {
      queryClient.invalidateQueries({
        queryKey: [
          ...PIPELINE_CANDIDATES_KEY,
          params.jobOpeningId,
          params.categoryId,
          { ...params.searchParams, sortOrder: params.sortOrder },
        ],
      });
    },
    [queryClient]
  );
};
